import React, { useState, useEffect } from 'react';
import { Row, Col, Flex, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CapacityWarning, InfoWarning } from 'components/Shared/Warnings';
import { TFunction } from 'i18next';
import { EAgeGroup, EWorkshopLanguage } from 'data/types/generic.types';
import Button from 'components/Base/Button/Button';
import { FormItem, FormList } from 'components/Base/Form';
import { Input } from 'components/Base/Input';
import { Select } from 'components/Base/Select';
import { DeleteTrashOutline } from 'assets/icon/icon';

export enum AGE_GROUPS {
  ZERO_TO_ONE = '0-1',
  ONE_TO_TWO = '1-2',
  TWO_TO_THREE = '2-3',
  TWO_TO_FIVE = '2-5',
  THREE_TO_FOUR = '3-4',
  THREE_TO_FIVE = '3-5',
  FOUR_TO_FIVE = '4-5',
}

export enum LANGUAGE {
  ENGLISH = 'English',
  SPANISH = 'Spanish',
}

export type AgeGroupDetail = {
  ageGroup: EAgeGroup;
  language: EWorkshopLanguage;
  capacity?: string;
  registrantsCount?: number;
  workshopClassId: string;
};

interface ChildrenInformationProps {
  t: TFunction;
  validationRules: {
    required: () => any;
    email: () => any;
    phoneNumber: () => any;
  };
  disabled?: boolean;
  ageGroupsDetails: AgeGroupDetail[];
  onCapacityCheck: (ageGroup: EAgeGroup) => boolean;
}

const ChildrenInformation: React.FC<ChildrenInformationProps> = ({
  t,
  validationRules,
  disabled,
  ageGroupsDetails,
  onCapacityCheck,
}) => {
  const [showCapacityWarning, setShowCapacityWarning] = useState(false);
  const [showChangeAgeGroup, setShowChangeAgeGroup] = useState(false);
  const [initialAgeGroup, setInitialAgeGroup] = useState<string | null>(null);

  // Get the form instance from the parent Form context
  const formInstance = Form.useFormInstance();

  // Store the initial age group when component mounts
  useEffect(() => {
    const workshopClassId = formInstance?.getFieldValue('workshopClassId');

    if (workshopClassId && !initialAgeGroup) {
      setInitialAgeGroup(workshopClassId);
    }
  }, [formInstance, initialAgeGroup]);

  const handleAgeGroupChange = (value: EAgeGroup) => {
    // Show capacity warning if needed
    if (onCapacityCheck && onCapacityCheck(value)) {
      setShowCapacityWarning(true);
    }

    // Show age group change warning if different from initial
    if (initialAgeGroup && value !== initialAgeGroup) {
      setShowChangeAgeGroup(true);
    } else {
      setShowChangeAgeGroup(false);
    }
  };

  return (
    <>
      <h3>{t('CHILDREN_INFO', 'Child(ren) Information')}</h3>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            name="workshopClassId"
            label={t('AGE_GROUP', 'Age Group')}
            rules={[validationRules.required()]}
            required
            data-cy="children-age-group"
          >
            <Select
              options={ageGroupsDetails.map(option => ({
                label: `${AGE_GROUPS[option.ageGroup]} ${
                  LANGUAGE[option.language]
                }`,
                value: option.workshopClassId,
              }))}
              onChange={handleAgeGroupChange}
              disabled={disabled}
            />
          </FormItem>
        </Col>

        <Col span={16}>
          <FormList name="childNames">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <FormItem
                    key={field.key}
                    label={index === 0 ? t('CHILD_NAME', 'Child Name') : ''}
                    rules={[validationRules.required()]}
                    required
                    style={{ marginBottom: 8 }}
                    data-cy="children-name"
                  >
                    <Flex gap={12} align="center">
                      <FormItem
                        {...field}
                        name={field.name}
                        noStyle
                        rules={[validationRules.required()]}
                      >
                        <Input placeholder={t('CHILD_NAME', 'Child Name')} />
                      </FormItem>
                      {fields.length > 1 && (
                        <Button
                          variant="ghost"
                          onClick={() => remove(field.name)}
                          data-cy="delete-children"
                        >
                          <DeleteTrashOutline size={16} />
                        </Button>
                      )}
                    </Flex>
                  </FormItem>
                ))}
                {fields.length > 0 && (
                  <FormItem>
                    <Button
                      variant="link"
                      onClick={() => add('')}
                      icon={<PlusOutlined />}
                      data-cy="add-children"
                    >
                      {t('ADD_CHILD', 'Add Child')}
                    </Button>
                  </FormItem>
                )}
              </>
            )}
          </FormList>
        </Col>
      </Row>

      {showCapacityWarning && (
        <CapacityWarning
          message="The age group you are trying to move this registrant to is at capacity. If you proceed the registrant will be added to the waitlist for that age group"
          onClose={() => setShowCapacityWarning(false)}
        />
      )}

      {showChangeAgeGroup && (
        <InfoWarning
          message="You are moving this registrant to another age group.  This will affect your kit order if it has already been placed."
          onClose={() => setShowChangeAgeGroup(false)}
        />
      )}
    </>
  );
};

export default ChildrenInformation;
