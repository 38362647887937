import { FC, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { EAgeGroup, TRtkErrorType } from 'data/types/generic.types';
import { useTranslation } from '@packages/utils';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import useForm from 'hooks/useForm';
import { useUpdateRegistrantMutation } from 'redux/apiSlice/registrants.slice';
import useGetValidationData from 'components/Base/Form/validationRules';
import {
  formatPhoneDisplay,
  formatPhoneToE164,
  isValidPhoneNumber,
  stripPhoneNumber,
} from 'helpers/phoneUtils';
import { Modal } from 'components/Base/AntdModal';
import { EWorkshopSeriesType } from 'data/types/workshops.types';
import {
  TEditRegistrantForm,
  TEditRegistrantModalProps,
} from './EditRegistrantModal.types';
import RegistrantInformation from './Sections/RegistrantInformation';
import AdditionalAdultInformation from './Sections/AdditionalAdultInformation';
import KitLanguageRadioGroup from './Sections/KitLanguageRadioGroup';
import ReadyBinderRadioGroup from './Sections/ReadyBinderRadioGroup';
import ChildrenInformation, {
  AgeGroupDetail,
} from './Sections/ChildrenInformaton';

const EditRegistrantModal: FC<TEditRegistrantModalProps> = ({
  open,
  setOpen,
  registrant,
  workshop,
  onSuccess,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.actions.edit.modal',
  });

  const { validationRules } = useGetValidationData();

  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TEditRegistrantForm>();
  const [updateRegistrant, { isLoading }] = useUpdateRegistrantMutation();

  const hasAttended = useMemo(
    () =>
      registrant.attendances.some(
        attendance => attendance.attendanceStatus === 'ATTENDED',
      ),
    [registrant.attendances],
  );

  const registrantAgeGroup = useMemo(() => {
    return (
      registrant.englishAgeGroups?.[0] ||
      registrant.spanishAgeGroups?.[0] ||
      null
    );
  }, [registrant.englishAgeGroups, registrant.spanishAgeGroups]);

  // This contains the age group, language, and capacity
  const ageGroupsDetails: AgeGroupDetail[] = useMemo(() => {
    if (!workshop?.classes?.value) return [];

    return workshop.classes.value.map(val => ({
      workshopClassId: val.id,
      ageGroup: val.ageGroup.value.value,
      language: val.language.value.value,
      capacity: val.capacity.value,
      registrantsCount: val.registrantsCount,
    }));
  }, [workshop]);

  useEffect(() => {
    if (open && registrant) {
      // Filter out additionalAdults with empty fields
      const defaultAdditionalAdult = (registrant.additionalAdults || [])
        .filter(
          adult =>
            adult.firstName && adult.lastName && adult.email && adult.phone,
        )
        .map(adult => ({
          ...adult,
          phone: formatPhoneDisplay(adult.phone),
        }));

      // Check if the ageGroup exists in available options
      const defaultAgeGroup = ageGroupsDetails.find(
        option =>
          option.ageGroup === registrantAgeGroup?.ageGroup &&
          option.language === registrantAgeGroup?.language,
      );

      // Initialize form with registrant data
      form.setFieldsValue({
        firstName: registrant.firstName,
        lastName: registrant.lastName,
        phone: registrant.phone,
        email: registrant.email,
        materialsLanguage: registrant.materialsLanguage,
        hasReadyBinder: registrant.orientationKit?.requested === true,
        childNames: registrant.childrenNames || [],
        workshopClassId: defaultAgeGroup?.workshopClassId || '',
        additionalAdults: defaultAdditionalAdult || [],
      });
    }
  }, [open, registrant, form]);

  const checkCapacity = (ageGroup: EAgeGroup) => {
    const ageGroupDetail = ageGroupsDetails.find(
      option => option.ageGroup === ageGroup,
    );

    const capacity = ageGroupDetail?.capacity;
    const registrantsCount = ageGroupDetail?.registrantsCount;

    // If capacity is empty string or undefined, no capacity limit is set
    if (capacity === '' || capacity === undefined) return false;

    // If registrantsCount is undefined, we can't determine if capacity is exceeded
    if (registrantsCount === undefined) return false;

    // Return true if registrantsCount is equal to or greater than capacity
    return Number(registrantsCount) >= Number(capacity);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const {
        workshopClassId,
        additionalAdults,
        hasReadyBinder,
        phone,
        ...rest
      } = values;
      if (!isValidPhoneNumber(phone)) {
        showToastNotification({
          message: 'Phone number must be exactly a 10-digit number',
          type: ENotificationType.ERROR,
        });
        return;
      }

      const phoneDigits = stripPhoneNumber(phone);

      const params = {
        ...rest,
        phone: formatPhoneToE164(phoneDigits),
        additionalAdults: additionalAdults?.map(adult => {
          const adultPhoneDigits = stripPhoneNumber(adult.phone);
          return {
            ...adult,
            phone: formatPhoneToE164(adultPhoneDigits),
          };
        }),
        hasReadyBinder: !hasReadyBinder,
      };

      if (workshop) {
        await updateRegistrant({
          workshopId: workshop.id,
          workshopClassId,
          registrantId: registrant.registrantId,
          ...params,
        }).unwrap();
      }

      showToastNotification({
        message: 'Registrant updated successfully!',
        type: ENotificationType.SUCCESS,
      });

      setOpen(false);

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if ((e as any)?.errorFields) {
        // Form validation error
        return;
      }

      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message || t('ERROR_MESSAGE'),
        type: ENotificationType.ERROR,
      });
    }
  };

  const hideBinders = useMemo(() => {
    return workshop?.type !== EWorkshopSeriesType.READY_FOR_KINDERGARTEN;
  }, [workshop]);

  return (
    <Modal
      title="Edit Registrant's Information"
      open={open}
      onCancel={() => setOpen(false)}
      onOk={handleSubmit}
      okText={t('SAVE_BTN', 'Save')}
      cancelText={t('CANCEL_BTN', 'Cancel')}
      confirmLoading={isLoading}
      width={800}
      destroyOnClose
      centered
    >
      <Form form={form} layout="vertical" requiredMark>
        <RegistrantInformation t={t} validationRules={validationRules} />
        <AdditionalAdultInformation t={t} validationRules={validationRules} />

        <KitLanguageRadioGroup
          t={t}
          validationRules={validationRules}
          disabled={hasAttended}
        />

        <ReadyBinderRadioGroup t={t} disabled={hasAttended || hideBinders} />

        <ChildrenInformation
          t={t}
          validationRules={validationRules}
          disabled={hasAttended}
          ageGroupsDetails={ageGroupsDetails}
          onCapacityCheck={checkCapacity}
        />
      </Form>
    </Modal>
  );
};

export default EditRegistrantModal;
