import { useTranslation } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { TabsProps } from '../../../../components/Base/Tabs/Tabs.types';
import AdminTab from '../AdminTab/AdminTab';
import UserTab from '../UserTab/UserTab';
import useKeepTabsInUrl from '../../../../hooks/useKeepTabsInUrl';
import { Tabs } from '../../../../components/Base/Tabs';

export enum ETeamTabs {
  ADMINS = 'admins',
  USERS = 'users',
}

const TeamList = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list',
  });

  const { onTabChange, tabInUrl } = useKeepTabsInUrl({
    defaultTab: ETeamTabs.ADMINS,
  });

  const TAB_ITEMS: TabsProps['items'] = [
    {
      key: ETeamTabs.ADMINS,
      label: t('tabs.ADMINS'),
      children: <AdminTab />,
    },
    {
      key: ETeamTabs.USERS,
      label: t('tabs.USERS'),
      children: <UserTab />,
    },
  ];

  return (
    <PageWrapper title={t('TITLE')}>
      <Tabs
        defaultActiveKey={tabInUrl}
        onChange={onTabChange}
        items={TAB_ITEMS}
      />
    </PageWrapper>
  );
};

export default TeamList;
