import React from 'react';
import { useTheme } from 'styled-components';
import { Button } from 'antd';

export type ButtonStatusProps = {
  status: 'PENDING' | 'IDLE' | 'INACTIVE' | 'ACTIVE' | 'EXPIRED';
  label?: string;
  style?: React.CSSProperties;
};

const StatusButton: React.FC<ButtonStatusProps> = ({
  status,
  label,
  style,
}) => {
  const theme = useTheme();

  const getButtonStyles = () => {
    switch (status) {
      case 'PENDING':
        return {
          backgroundColor: '#F5F5F5',
          borderColor: '#B5B5B5',
          color: '#414042',
        };
      case 'IDLE':
        return {
          backgroundColor: '#F7E9F0',
          borderColor: '#DED1D8',
          color: '#853E75',
        };
      case 'INACTIVE':
        return {
          backgroundColor: '#FFF7F0',
          borderColor: '#FFAA75',
          color: '#CC4712',
        };
      case 'ACTIVE':
        return {
          backgroundColor: '#DCF9EA',
          borderColor: '#1FC16E',
          color: '#116A3C',
        };
      case 'EXPIRED':
        return {
          backgroundColor: '#FFF7F0',
          borderColor: '#FFAA75',
          color: '#CC4712',
        };
      default:
        return theme.colorPrimary;
    }
  };

  const buttonStyles = getButtonStyles();

  return (
    <Button
      type="primary"
      style={{ ...style, ...buttonStyles, boxShadow: 'none' }}
    >
      {label || status.toUpperCase()}
    </Button>
  );
};

export default StatusButton;
