import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';

export type ButtonVariant = 'primary' | 'default' | 'text' | 'link' | 'ghost';

export const StyledButton = styled(AntButton)<{
  $variant: ButtonVariant;
  $fullWidth?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;

  ${props => props.$fullWidth && 'width: 100%;'}

  ${props => {
    switch (props.$variant) {
      case 'primary':
        return css`
          background-color: #0077a0;
          color: #ffffff;
          border: none;
          &:hover {
            background-color: #0197b8 !important;
            color: #ffffff !important;
          }
          &:active {
            background-color: #005678 !important;
            color: #ffffff !important;
          }
          &:disabled {
            background-color: #f5f5f5 !important;
            color: #808080 !important;
            cursor: not-allowed;
          }
        `;
      case 'default':
        return css`
          background-color: #ffffff;
          color: #414042;
          border: 1px solid #dedede;
          &:hover {
            border-color: #0077a0 !important;
            color: #1b1a1c !important;
          }
          &:active {
            border-color: #0077a0 !important;
            color: #005678 !important;
          }
          &:disabled {
            color: #808080 !important;
            border-color: #dedede !important;
            cursor: not-allowed;
          }
        `;
      case 'text':
        return css`
          background-color: transparent;
          color: #414042;
          border: none;
          box-shadow: none;
          &:hover {
            background-color: #f3f3f3 !important;
          }
          &:active {
            background-color: transparent !important;
            color: #414042 !important;
          }
          &:disabled {
            color: #808080 !important;
            cursor: not-allowed;
            background-color: transparent !important;
          }
        `;
      case 'link':
        return css`
          background-color: transparent;
          color: #0077a0;
          border: none;
          box-shadow: none;
          &:hover {
            color: #005678 !important;
          }
          &:active {
            color: #005678 !important;
          }
          &:disabled {
            color: #808080 !important;
            cursor: not-allowed;
            background-color: transparent !important;
          }
        `;
      case 'ghost':
        return css`
          background-color: transparent;
          color: #414042;
          border: none;
          box-shadow: none;
          padding: 4px 8px;
          &:hover {
            color: #414042 !important;
            background-color: transparent !important;
          }
          &:active {
            color: #00779f !important;
            background-color: transparent !important;
          }
          &:disabled {
            color: #b3d9e3 !important;
            cursor: not-allowed;
            background-color: transparent !important;
          }
        `;
      default:
        return '';
    }
  }}
  
  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 136, 176, 0.2);
  }
`;
