import React from 'react';
import { TFunction } from 'i18next';
import { FormItem } from 'components/Base/Form';
import { Radio, RadioGroup } from 'components/Base/RadioGroup';

interface MaterialsLanguageSectionProps {
  t: TFunction;
  validationRules: {
    required: () => any;
    email: () => any;
    phoneNumber: () => any;
  };
  disabled?: boolean;
}

const KitLanguageRadioGroup: React.FC<MaterialsLanguageSectionProps> = ({
  t,
  validationRules,
  disabled,
}) => {
  return (
    <FormItem
      name="materialsLanguage"
      label={t('KIT_LANGUAGE', 'Kit Language')}
      rules={[validationRules.required()]}
      required
    >
      <RadioGroup disabled={disabled}>
        <Radio value="ENGLISH">{t('ENGLISH', 'English')}</Radio>
        <Radio value="SPANISH">{t('SPANISH', 'Spanish')}</Radio>
      </RadioGroup>
    </FormItem>
  );
};

export default KitLanguageRadioGroup;
