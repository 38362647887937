import { Col, Title } from '@packages/utils';
import {
  SContentHeaderWrapper,
  SMainContentWrapper,
  SCardContentWrapper,
} from '../Content.styles';
import { TCardContentProps } from './CardContent.types';

const CardContent = ({
  children,
  contentPadding = '0',
  title,
  actions,
  tabContent,
  style,
}: TCardContentProps) => {
  return (
    <SMainContentWrapper
      className={tabContent ? '' : 'card-content'}
      style={{ ...style }}
    >
      {title && (
        <SContentHeaderWrapper
          className="section-header"
          justify="space-between"
          align="middle"
        >
          <Col>
            <Title level={3} color="#000000">
              {title}
            </Title>
          </Col>
          {actions && <Col>{actions}</Col>}
        </SContentHeaderWrapper>
      )}
      <SCardContentWrapper $contentPadding={contentPadding}>
        {children}
      </SCardContentWrapper>
    </SMainContentWrapper>
  );
};

export default CardContent;
