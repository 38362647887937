import { useState } from 'react';
import { useTranslation } from '@packages/utils';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { useDeleteRegistrantMutation } from 'redux/apiSlice/registrants.slice';

interface DeleteRegistrantParams {
  workshopId: string;
  registrantId: string;
}

export const useDeleteRegistrant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { showToastNotification } = useShowToastNotification();
  const [deleteRegistrantMutation] = useDeleteRegistrantMutation();

  const deleteRegistrant = async ({
    workshopId,
    registrantId,
  }: DeleteRegistrantParams) => {
    setIsLoading(true);

    try {
      await deleteRegistrantMutation({ workshopId, registrantId }).unwrap();

      showToastNotification({
        message: t(
          'descriptions.admin-portal.workshops.details.workshop-registrants.actions.delete.modal.SUCCESS_MESSAGE',
        ),
        type: ENotificationType.SUCCESS,
      });

      return true;
    } catch (err) {
      console.error('Delete operation failed:', err);

      showToastNotification({
        message:
          (err as TRtkErrorType)?.data?.message ||
          t(
            'descriptions.admin-portal.workshops.details.workshop-registrants.actions.delete.modal.ERROR_MESSAGE',
          ),
        type: ENotificationType.ERROR,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteRegistrant, isLoading };
};
