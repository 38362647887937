import React from 'react';
import { ButtonProps as AntButtonProps, Tooltip } from 'antd';
import { StyledButton, ButtonVariant } from './Button.styles';

export interface ButtonProps extends AntButtonProps {
  variant?: ButtonVariant;
  fullWidth?: boolean;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'middle',
  type,
  fullWidth = false,
  children,
  tooltip,
  className,
  ...props
}) => {
  const button = (
    <StyledButton
      $variant={variant}
      $fullWidth={fullWidth}
      type={type}
      size={size}
      className={className}
      {...props}
    >
      {children}
    </StyledButton>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export default Button;
