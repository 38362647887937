import { FC, useEffect, useMemo } from 'react';
import { dateShortenFormat } from '@idaho-aeyc/ui-kit';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import { Table } from 'components/Base/Table';
import { EWaitListColumnKey, TWaitListUI } from 'data/types/registrants.types';
import { useGetWaitListQuery } from 'redux/apiSlice/registrants.slice';
import { EIconNames } from 'components/Base/Icon';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { collectAgeGroupColumnItemsInList } from 'components/Shared/AgeGroupesColumn/helpers';
import { workshopsApi } from 'redux/apiSlice/workshops.slice';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { EWorkshopStatus } from 'data/types/workshops.types';
import { TWorkshopWaitListProps } from './WorkshopWaitList.types';
import useGetData from './WorkshopWaitList.data';
import MoveAction from './Actions/MoveAction';
import { EUserType } from '../../../../../../../data/types/user.types';
import { useAppSelector } from '../../../../../../../redux/store';

const ADMIN_TYPES = [
  EUserType.READY_ADMIN,
  EUserType.SUPER_ADMIN,
  EUserType.ELC_LEAD,
];

const WorkshopWaitList: FC<TWorkshopWaitListProps> = ({
  workshopId,
  customQuestion,
  csvFileNamePrefix,
  hideChildInfo,
}) => {
  const usersData = useAppSelector(state => state.userSlice);

  const isAdmin = useMemo(() => {
    const user = usersData?.user;
    return user?.type?.some((type: EUserType) => ADMIN_TYPES.includes(type));
  }, [usersData?.user]);

  const { defaultColumns, defaultSortParam } = useGetData();
  const params = useParams();
  const { id } = params as { id: string };
  const { t: tEnums } = useTranslation({
    keyPrefix: 'enums',
  });

  const { data: workshopDetailsCachedData } =
    workshopsApi.endpoints.getWorkshopById.useQueryState(
      {
        id,
      },
      { skip: !id },
    );
  const workshopPossibleAgeGroups =
    workshopDetailsCachedData?.possibleAgeGroups;

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TWaitListUI>({
    storagePath: `${ETableStoragePaths.WORKSHOP_WAIT_LIST}-${workshopId}`,
    defaultColumns,
    defaultSortColumn: defaultSortParam,
  });
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.workshop-wait-list',
  });

  const { data, isFetching, isLoading } = useGetWaitListQuery({
    page: 0,
    pageSize: 2000,
    sortColumn,
    filters: selectedFilters,
    workshopId,
  });

  const isReadonly =
    workshopDetailsCachedData?.status !== EWorkshopStatus.PUBLISHED ||
    (workshopDetailsCachedData?.status === EWorkshopStatus.PUBLISHED &&
      data?.allowedOperations.readonly);

  useEffect(() => {
    if (!data) {
      return;
    }
    const cols = columns.map(item => {
      if (!isReadonly && item.dataIndex === 'actions') {
        item.render = (_, record) => {
          return (
            <MoveAction
              workshopClassId={record.workshopClassId}
              registrantId={record.registrantId}
              workshopId={workshopId}
            />
          );
        };
      }
      return item;
    });
    if (!customQuestion || hideChildInfo) {
      const filteredColumns = cols.filter(col => {
        if (!customQuestion && col.dataIndex === 'customQuestionAnswer') {
          return false;
        }
        return !(
          hideChildInfo &&
          (col.dataIndex === 'countOfChildren' ||
            col.dataIndex === 'childrenNames')
        );
      });
      setColumns(filteredColumns);
    }
  }, [data]);

  useEffect(() => {
    if (workshopPossibleAgeGroups) {
      setColumns(
        columns.map(item => {
          if (item.key === EWaitListColumnKey.AGE_GROUP) {
            item.filters = workshopPossibleAgeGroups.map(ageGroup => ({
              text: tEnums(`age-group.${ageGroup}`),
              value: ageGroup,
            }));
          }
          return item;
        }),
      );
    }
  }, [workshopPossibleAgeGroups]);

  const csvFileName = `${csvFileNamePrefix}_${t('TITLE')}_${dayjs().format(
    dateShortenFormat,
  )}.csv`;
  return (
    <TabBodyWrapper
      isEmpty={!data?.content.length && !hasSelectedFilter && !isFetching}
      isLoading={isLoading}
      emptyText={t('empty-view.TITLE')}
      emptyIcon={EIconNames.SAND_TIMER_FILLED}
    >
      <Table<TWaitListUI>
        exportCSV={{
          filename: csvFileName,
          exportData: (data?.content || []).map(el => {
            const ageGroup = collectAgeGroupColumnItemsInList(
              el.englishAgeGroups.concat(el.spanishAgeGroups),
            );
            return {
              ...el,
              ageGroup,
            };
          }),
        }}
        tableTitle={t('TITLE')}
        tabHeight={44}
        onFiltersReset={onFiltersReset}
        hasFilters={hasFilters}
        currentPage={page}
        columns={columns}
        dataSource={data?.content}
        loading={isFetching}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalDataCount={data?.totalElements}
        onChange={handleTableChange}
        rowKey={record => record.registrantId}
        rowClassName={!isAdmin ? 'pe-none' : ''}
        storagePath={ETableStoragePaths.WORKSHOP_WAIT_LIST}
      />
    </TabBodyWrapper>
  );
};

export default WorkshopWaitList;
