import { useState } from 'react';
import { useTranslation } from '@packages/utils';
import { useGetUsersListQuery } from 'redux/apiSlice/userGrow.slice';
import { Table } from 'components/Base/Table';
import useTable from 'components/Base/Table/hooks/useTable';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { TAdminsDataUI } from 'data/types/admin.types';
import useNavigateWithBackState from 'hooks/useNavigateWithBackState';
import { styled } from 'styled-components';
import useGetTeamTableData from './UserList.data';
import { Alert } from '../../../../components/Base/Alert';
import ExportActions from '../TeamAction/Export/ExportAction';

const StyledTable = styled(Table)<{ dataSource?: TAdminsDataUI[] }>`
  .ant-table-content {
    max-height: calc(100vh - 290px) !important; // Adjust 300px as needed
    overflow-y: hidden;
  }
`;

const UserTab = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table',
  });

  const { defaultColumns } = useGetTeamTableData();

  const [showAlert, setShowAlert] = useState(false);

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasFilters,
    onFiltersReset,
    columns,
    selectedRows,
    setSelectedRows,
  } = useTable<TAdminsDataUI>({
    storagePath: ETableStoragePaths.TEAM_USERS,
    defaultColumns,
  });

  const { data, isFetching } = useGetUsersListQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const { goPeopleDetails } = useNavigateWithBackState();

  const navigateToPeopleDetails = (record: TAdminsDataUI) => {
    return {
      onClick: () => goPeopleDetails(record.adminId, t('TITLE')),
    };
  };

  return (
    <CardContent style={{ padding: 0, margin: 0, overflow: 'hidden' }}>
      {showAlert && (
        <div className="mx-4" style={{ minHeight: '1px' }}>
          <Alert
            type="warning"
            closable
            message={t('ACTION_ALERT_MESSAGE')}
            className="mt-4"
            onClose={() => setShowAlert(false)}
          />
        </div>
      )}
      <StyledTable
        tableTitle={t('USERS')}
        hasFilters={hasFilters}
        onFiltersReset={onFiltersReset}
        rowKey={(record: TAdminsDataUI) => record.adminId}
        currentPage={page}
        columns={columns as any[] | undefined}
        dataSource={data?.content}
        rowClassName="pointer"
        onRow={navigateToPeopleDetails}
        loading={isFetching}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalDataCount={data?.totalElements}
        onChange={handleTableChange}
        storagePath={ETableStoragePaths.TEAM_USERS}
        additionalActions={[
          <ExportActions
            setShowAlert={setShowAlert}
            selectedRowsKeys={selectedRows.selectedRowsKeys}
          />,
        ]}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows as any}
      />
    </CardContent>
  );
};

export default UserTab;
