import React from 'react';
import { TFunction } from 'i18next';
import { FormItem } from 'components/Base/Form';
import { Radio, RadioGroup } from 'components/Base/RadioGroup';

interface ReadyBinderRadioGroupProps {
  t: TFunction;
  disabled?: boolean;
}

const ReadyBinderRadioGroup: React.FC<ReadyBinderRadioGroupProps> = ({
  t,
  disabled,
}) => {
  return (
    <FormItem
      name="hasReadyBinder"
      label={t('BINDER_REQUIRED', 'Binder Required')}
      required
    >
      <RadioGroup disabled={disabled}>
        <Radio value>{t('YES', 'Yes')}</Radio>
        <Radio value={false}>{t('NO', 'No')}</Radio>
      </RadioGroup>
    </FormItem>
  );
};

export default ReadyBinderRadioGroup;
