import { styled } from 'styled-components';

export const InputWrapper = styled.div<{ $width?: number | string }>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colorBlackMainBorder};
  border-radius: 4px;
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
  overflow: hidden;
`;

export const Button = styled.button<{
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 8px;

  ${props =>
    props.$disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
    `}
`;

export const InputField = styled.div`
  flex: 1;
  text-align: center;
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.colorBlackMainText};
`;
