export interface IconProps {
  /**
   * Optional, fill color
   */
  fill?: string;
  /**
   * Optional, size of the svg icon
   */
  size?: number;
  /**
   * Optional, on click event.
   */
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
}

export function EmailOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-email-outline"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M3.2 28.8c-0.88 0-1.632-0.314-2.261-0.941-0.58-0.561-0.94-1.346-0.94-2.215 0-0.015 0-0.031 0-0.046l-0 0.002v-19.2c0-0.88 0.314-1.632 0.941-2.261 0.561-0.58 1.346-0.939 2.215-0.939 0.015 0 0.031 0 0.046 0l-0.002-0h25.6c0.88 0 1.632 0.314 2.261 0.941 0.624 0.626 0.939 1.379 0.939 2.259v19.2c0 0.88-0.314 1.632-0.939 2.259-0.561 0.581-1.347 0.941-2.217 0.941-0.015 0-0.031-0-0.046-0l0.002 0h-25.6zM16 17.6l-12.8-8v16h25.6v-16l-12.8 8zM16 14.4l12.8-8h-25.6l12.8 8zM3.2 9.6v0z"
      />
    </svg>
  );
}

export function PhoneOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-phone-outline"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M30.133 32c-3.703 0-7.364-0.807-10.977-2.423-3.614-1.614-6.903-3.902-9.866-6.866s-5.252-6.252-6.866-9.866c-1.618-3.614-2.423-7.275-2.423-10.977 0-0.533 0.178-0.978 0.533-1.333s0.8-0.533 1.333-0.533h7.2c0.003 0 0.005-0 0.008-0 0.424 0 0.811 0.16 1.104 0.423l-0.002-0.001c0.301 0.247 0.511 0.595 0.576 0.991l0.001 0.009 1.156 6.222c0.059 0.475 0.044 0.875-0.044 1.2s-0.252 0.608-0.489 0.844l-4.311 4.356c0.592 1.097 1.298 2.156 2.11 3.177 0.816 1.022 1.712 2.009 2.69 2.956 0.919 0.919 1.881 1.771 2.889 2.556 1.008 0.784 2.075 1.502 3.2 2.155l4.178-4.178c0.285-0.275 0.636-0.482 1.028-0.596l0.018-0.004c0.261-0.087 0.562-0.137 0.875-0.137 0.138 0 0.273 0.010 0.406 0.029l-0.015-0.002 6.133 1.244c0.414 0.119 0.756 0.334 1.022 0.645 0.248 0.275 0.4 0.642 0.4 1.044v7.2c0 0.533-0.178 0.978-0.533 1.333s-0.8 0.533-1.333 0.533zM5.378 10.667l2.933-2.933-0.756-4.178h-3.956c0.148 1.214 0.356 2.414 0.622 3.6s0.652 2.356 1.156 3.511zM21.289 26.578c1.156 0.503 2.334 0.903 3.534 1.2s2.407 0.489 3.621 0.578v-3.911l-4.178-0.844-2.978 2.978z"
      />
    </svg>
  );
}

export function DeleteTrashOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-delete-trash-icon"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M7.113 32c-0.98 0-1.816-0.348-2.512-1.044-0.644-0.623-1.044-1.496-1.044-2.462 0-0.018 0-0.035 0-0.053v0.003-23.11h-1.78v-3.556h8.89v-1.778h10.668v1.778h8.888v3.556h-1.778v23.11c0 0.98-0.348 1.816-1.044 2.512-0.623 0.644-1.496 1.044-2.462 1.044-0.017 0-0.034-0-0.051-0h-17.777zM24.891 5.334h-17.78v23.11h17.78v-23.11zM10.667 24.888h3.556v-16h-3.556v16zM17.779 24.888h3.556v-16h-3.556v16z"
      />
    </svg>
  );
}

export function EditPencilOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-edit-pencil-icon"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M2.634 29.367h2.252l19.921-19.923-2.25-2.25-19.923 19.921v2.252zM0.001 32v-5.995l25.195-25.235c0.273-0.275 0.567-0.474 0.881-0.593 0.298-0.112 0.643-0.176 1.003-0.176 0.347 0 0.68 0.060 0.99 0.171l-0.021-0.006c0.314 0.111 0.616 0.308 0.911 0.587l2.268 2.252c0.282 0.294 0.48 0.597 0.595 0.911 0.111 0.291 0.175 0.627 0.175 0.979 0 0.362-0.068 0.707-0.192 1.025l0.007-0.019c-0.122 0.324-0.316 0.618-0.585 0.885l-25.233 25.215h-5.995zM23.662 8.34l-1.106-1.146 2.25 2.252-1.144-1.106z"
      />
    </svg>
  );
}

export function PersonRemoveOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-person-remove-icon"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M22.857 14.476v-3.048h9.143v3.048h-9.143zM12.19 16c-1.676 0-3.112-0.597-4.305-1.79s-1.79-2.629-1.79-4.305 0.597-3.112 1.79-4.305c1.193-1.193 2.629-1.79 4.305-1.79s3.112 0.597 4.305 1.79c1.193 1.193 1.79 2.629 1.79 4.305s-0.597 3.112-1.79 4.305c-1.193 1.193-2.629 1.79-4.305 1.79zM0 28.19v-4.267c0-0.864 0.222-1.656 0.667-2.382 0.443-0.724 1.035-1.275 1.771-1.656 1.373-0.711 2.972-1.318 4.644-1.739l0.156-0.033c1.483-0.375 3.185-0.59 4.938-0.59 0.005 0 0.010 0 0.015 0h-0.001c1.676 0 3.326 0.198 4.952 0.59 1.626 0.395 3.226 0.984 4.8 1.772 0.736 0.381 1.326 0.933 1.771 1.656 0.445 0.725 0.667 1.518 0.667 2.382v4.267h-24.381zM3.048 25.143h18.286v-1.219c-0-0.554-0.304-1.037-0.755-1.291l-0.007-0.004c-1.371-0.686-2.755-1.199-4.152-1.542-1.27-0.326-2.727-0.513-4.229-0.513s-2.959 0.187-4.351 0.539l0.122-0.026c-1.397 0.343-2.781 0.856-4.152 1.542-0.458 0.258-0.762 0.741-0.762 1.295v1.219zM12.19 12.952c0.838 0 1.554-0.299 2.152-0.896 0.597-0.596 0.896-1.314 0.896-2.152s-0.299-1.554-0.896-2.152c-0.534-0.553-1.283-0.896-2.111-0.896-0.014 0-0.028 0-0.042 0l0.002-0c-0.838 0-1.554 0.299-2.152 0.896-0.553 0.534-0.896 1.283-0.896 2.111 0 0.014 0 0.028 0 0.042l-0-0.002c0 0.838 0.299 1.554 0.896 2.152 0.596 0.597 1.314 0.896 2.152 0.896z"
      />
    </svg>
  );
}
