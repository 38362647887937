import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import { workshopsApi } from 'redux/apiSlice/workshops.slice';
import { Table } from 'components/Base/Table';
import {
  ERegistrantsColumnKey,
  TRegistrantUI,
} from 'data/types/registrants.types';
import {
  useGetRegistrantsQuery,
  useGetValidateExportQuery,
} from 'redux/apiSlice/registrants.slice';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { EWorkshopStatus } from 'data/types/workshops.types';
import DeleteRegistrantModal from 'components/Shared/DeleteRegistrantModal/DeleteRegistrantModal';
import { useDeleteRegistrant } from 'hooks/useDeleteRegistrant';
import { EUserType } from 'data/types/user.types';
import { useAppSelector } from 'redux/store';
import { Flex } from 'antd';
import Button from 'components/Base/Button/Button';
import { EditPencilOutline, PersonRemoveOutline } from 'assets/icon/icon';
import useGetRegistrantsColumnsData, {
  filterRegistrantsColumns,
} from './RegistrantsList.data';
import { TRegistrantsListProps } from './RegistrantsList.types';
import useAgeGroupLanguageFilter from './useAgeGroupLanguageFilter';
import Attendances from './Attendances';
import RegistrantsExport from './RegistrantsAction/RegistrantsExport';
import EditRegistrantModal from './EditRegistrantModal/EditRegistrantModal';

const ADMIN_TYPES = [
  EUserType.READY_ADMIN,
  EUserType.SUPER_ADMIN,
  EUserType.ELC_LEAD,
];

const RegistrantsList: FC<TRegistrantsListProps> = ({
  workshopId = '',
  workshop,
  customQuestion,
  hideChildInfo,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRegistrant, setSelectedRegistrant] =
    useState<TRegistrantUI | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { deleteRegistrant, isLoading: isDeleteLoading } =
    useDeleteRegistrant();

  const usersData = useAppSelector(state => state.userSlice);

  const isAdmin = useMemo(() => {
    const user = usersData?.user;
    return user?.type?.some((type: EUserType) => ADMIN_TYPES.includes(type));
  }, [usersData?.user]);

  const handleEditRegistrant = (registrant: TRegistrantUI) => {
    if (registrant.isWorkshopClosed) return;
    setSelectedRegistrant(registrant);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (record: TRegistrantUI) => {
    if (record.isWorkshopClosed) return;
    setSelectedRegistrant(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRegistrant && workshopId) {
      const success = await deleteRegistrant({
        workshopId,
        registrantId: selectedRegistrant.registrantId,
      });

      if (success) {
        setIsDeleteModalOpen(false);
        setSelectedRegistrant(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedRegistrant(null);
  };
  const { workshopRegistrantsColumns } = useGetRegistrantsColumnsData(
    handleEditRegistrant,
    handleDeleteClick,
  );
  const { data: isExportValid } = useGetValidateExportQuery(
    {
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  const params = useParams();
  const { id } = params as { id: string };

  const { data: workshopDetailsCachedData } =
    workshopsApi.endpoints.getWorkshopById.useQueryState(
      {
        id,
      },
      { skip: !id },
    );

  const workshopClasses = workshopDetailsCachedData?.classes.value || [];
  const workshopStatus = workshopDetailsCachedData?.status;

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TRegistrantUI>({
    storagePath: `${ETableStoragePaths.WORKSHOP_REGISTRANTS}-${workshopId}`,
    defaultColumns: workshopRegistrantsColumns,
  });

  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants',
  });

  const { data, isFetching, isLoading, isSuccess } = useGetRegistrantsQuery(
    {
      page: 0,
      pageSize: 2000,
      sortColumn,
      filters: selectedFilters,
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === 'attendances') {
          item.render = (_, record) => {
            const isEditable = !record.isWorkshopClosed;
            const isDeletable =
              !record.hasAttendedWorkshop &&
              !record.isWorkshopClosed &&
              !record.nonDeleteReason;

            return (
              <Flex align="center" gap={24}>
                <Flex gap={4}>
                  <Button
                    variant="ghost"
                    tooltip={isEditable ? 'Edit Registrant' : undefined}
                    icon={
                      <EditPencilOutline
                        size={20}
                        fill={isEditable ? '#656565' : '#808080'}
                      />
                    }
                    onClick={() => handleEditRegistrant(record)}
                    disabled={!isEditable}
                    className={!isEditable ? 'cursor-not-allowed' : ''}
                  />

                  {isDeletable ? (
                    <Button
                      variant="ghost"
                      tooltip="Delete Registrant"
                      icon={<PersonRemoveOutline size={24} fill="#656565" />}
                      onClick={() => handleDeleteClick(record)}
                      disabled={
                        record.isWorkshopClosed === false &&
                        record.hasAttendedWorkshop
                      }
                      className={
                        record.isWorkshopClosed === false &&
                        record.hasAttendedWorkshop
                          ? 'cursor-not-allowed'
                          : ''
                      }
                    />
                  ) : (
                    <Button
                      variant="ghost"
                      tooltip={
                        record.nonDeleteReason || "Can't delete registrant"
                      }
                      icon={<PersonRemoveOutline size={24} fill="#808080" />}
                      disabled
                      className="cursor-not-allowed"
                    />
                  )}
                </Flex>

                <Attendances
                  isReadonly={
                    workshopStatus !== EWorkshopStatus.PUBLISHED ||
                    data?.allowedOperations.readonly
                  }
                  key={record.registrantId}
                  attendances={record.attendances}
                  fullName={record.fullName}
                  hasUpdatableAttendance={record.hasUpdatableAttendance}
                  registrantId={record.registrantId}
                  workshopClassId={record.workshopClassId}
                  orientationKit={record.orientationKit}
                  hasStartedPart={!!workshopDetailsCachedData?.hasStartedPart}
                  materialsLanguage={record.materialsLanguage}
                  workshopId={workshopId}
                  registrantStatus={record.registrantStatus}
                />
              </Flex>
            );
          };
        }
        return item;
      }),
    );
    filterRegistrantsColumns({
      columns,
      setColumns,
      customQuestion,
      hideChildInfo,
    });
  }, [
    isSuccess,
    workshopStatus,
    workshopDetailsCachedData?.hasStartedPart,
    hideChildInfo,
    customQuestion,
  ]);

  const ageGroupLanguageFilters = useAgeGroupLanguageFilter(workshopClasses);
  useEffect(() => {
    if (ageGroupLanguageFilters) {
      setColumns(
        columns.map(item => {
          if (
            item.key ===
              ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP &&
            ageGroupLanguageFilters
          ) {
            item.filters = ageGroupLanguageFilters;
          }
          return item;
        }),
      );
    }
  }, [workshopClasses]);

  const handleEditSuccess = () => {
    // Refresh your data or perform any necessary actions after successful update
    // For example:
    // refetchRegistrants();
  };

  return (
    <>
      <TabBodyWrapper
        isEmpty={!data?.content.length && !hasSelectedFilter && !isFetching}
        isLoading={isLoading}
        emptyText={t('empty-view.TITLE')}
      >
        <Table<TRegistrantUI>
          {...(isExportValid && {
            additionalActions: (
              <RegistrantsExport
                workshopId={workshopId}
                selectedFilters={selectedFilters}
              />
            ),
          })}
          tabHeight={44}
          tableTitle={t('TITLE')}
          onFiltersReset={onFiltersReset}
          hasFilters={hasFilters}
          currentPage={page}
          columns={columns}
          dataSource={data?.content}
          loading={isFetching}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={data?.totalElements}
          onChange={handleTableChange}
          rowKey={record => record.registrantId}
          rowClassName={record => {
            let className = '';

            if (record.isWorkshopClosed) {
              className += 'disabled-registrant-row ';
            }

            if (!isAdmin) {
              className += 'pe-none ';
            }

            return className.trim();
          }}
          storagePath={ETableStoragePaths.WORKSHOP_REGISTRANTS}
        />
      </TabBodyWrapper>
      {selectedRegistrant && (
        <EditRegistrantModal
          open={isEditModalOpen}
          setOpen={setIsEditModalOpen}
          registrant={selectedRegistrant}
          workshop={workshop}
          onSuccess={handleEditSuccess}
        />
      )}

      <DeleteRegistrantModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default RegistrantsList;
