import { Select, SelectWithAdd } from '@idaho-aeyc/ui-kit';
import styled from 'styled-components';

export const SSelect = styled(Select)`
  && {
    .ant-select-selection-item {
      font-weight: 400 !important;
    }

    .ant-select-selector {
      transition: all 0.3s;
    }

    &:hover .ant-select-selector {
      border-color: #0197b8 !important;
    }

    &.ant-select-focused .ant-select-selector,
    &.ant-select-open .ant-select-selector {
      border-color: #005678 !important;
      box-shadow: 0 0 0 2px rgba(0, 86, 120, 0.2) !important;
    }

    &.ant-select-disabled {
      .ant-select-selector {
        background-color: #f5f5f5 !important;
        border-color: #dedede !important;
      }

      .ant-select-selection-item {
        color: #808080 !important;
      }
    }
  }
`;

export const SSelectWithAdd = styled(SelectWithAdd)``;
