import React from 'react';
import { Modal, Button, Space } from 'antd';
import { useTranslation } from '@packages/utils';
import styled from 'styled-components';

interface DeleteRegistrantModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #000000;
`;

const ModalContent = styled.p`
  font-size: 16px;
  padding: 16px 24px;
  line-height: 1.5;
  color: #414042;
`;

const CancelButton = styled(Button)`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Main-border, #DEDEDE);
  background: white;
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    background: #e6e6e6;
    border-color: #c4c4c4;
  }
  .anticon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UnregisterButton = styled(Button)`
  display: flex;
  padding: 0px 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Error-Active, #C71A14);
  color: white;
  border: none;
  &:hover {
    background: #9e1410 !important;
    color: white !important;
  }
  .ant-btn-loading-icon {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
  }
`;

const DeleteRegistrantModal: React.FC<DeleteRegistrantModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      closable
      width={544}
      centered
      title={
        <ModalTitle
          style={{
            paddingTop: '16px',
            paddingRight: '24px',
            paddingBottom: '8px',
            paddingLeft: '24px',
          }}
        >
          Unregister Registrant
        </ModalTitle>
      }
      modalRender={node => {
        return React.cloneElement(node as React.ReactElement, {
          style: {
            ...(node as React.ReactElement).props.style,
            padding: 0,
          },
        });
      }}
    >
      <ModalContent>
        If you choose to unregister the registrant, they will no longer receive
        notifications and will be taken off the orders and attendance lists.
        <br />
        <span style={{ fontWeight: 600 }}>
          Are you sure you want to unregister them?
        </span>
      </ModalContent>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0px 24px',
          paddingBottom: '16px',
        }}
      >
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <UnregisterButton onClick={onConfirm} loading={isLoading}>
          Unregister
        </UnregisterButton>
      </Space>
    </Modal>
  );
};

export default DeleteRegistrantModal;
