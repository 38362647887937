import React, { FC, CSSProperties } from 'react';
import { Flex } from 'antd';
import { Button } from './ButtonGroup.styles';

export interface ButtonOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface ButtonGroupProps {
  options: ButtonOption[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  size?: 'small' | 'middle' | 'large';
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  options,
  value,
  onChange,
  disabled = false,
  className,
  style,
  size = 'middle',
}) => {
  const getButtonPosition = (index: number) => {
    if (index === 0) return 'first';
    if (index === options.length - 1) return 'last';
    return 'middle';
  };

  return (
    <Flex className={className} style={style}>
      {options.map((option, index) => (
        <Button
          key={option.value}
          $position={getButtonPosition(index)}
          $active={value === option.value}
          $disabled={disabled || option.disabled || false}
          disabled={disabled || option.disabled || false}
          onClick={() => onChange(option.value)}
          size={size}
        >
          {option.label}
        </Button>
      ))}
    </Flex>
  );
};

export default ButtonGroup;
