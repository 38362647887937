import {
  PasswordInput,
  TextArea,
  Input,
  PhoneInput,
  RichTextEditor,
} from '@idaho-aeyc/ui-kit';
import styled, { css } from 'styled-components';

// Common input styles that can be shared across all input types
export const createInputStyles = () => css`
  font-weight: 400 !important;

  &:hover,
  &:hover .ant-input {
    border-color: #0197b8 !important;
  }

  &:focus,
  &:focus-within,
  &.ant-input-focused,
  &.ant-input-affix-wrapper-focused {
    border-color: #005678 !important;
    box-shadow: 0 0 0 2px rgba(0, 86, 120, 0.2) !important;
  }

  &.ant-input-disabled,
  &.ant-input-affix-wrapper-disabled {
    border-color: #d9d9d9 !important;

    .ant-input {
      color: #b5b5b5 !important;
    }
  }
`;

export const SInput = styled(Input)`
  ${createInputStyles}
`;

export const SPassword = styled(PasswordInput)`
  ${createInputStyles}
`;

export const STextArea = styled(TextArea)`
  ${createInputStyles}
`;

export const SPhoneInput = styled(PhoneInput)`
  ${createInputStyles}
`;

export const SRichTextEditor = styled(RichTextEditor)`
  ${createInputStyles}

  // Additional specific styling for rich text editor if needed
  .ql-container {
    &:hover {
      border-color: #0197b8 !important;
    }
  }
`;
