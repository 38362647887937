export enum EUserUrls {
  BASE = 'users',
  PROFILE = 'profile',
  INVITE = 'invite',
  IDENTITY = 'identity',
  ACCEPT_INVITE = 'accept-invite',
  NO_PROFILE = 'no-profile',
  TEAM = 'team',
  RESEND_INVITE = 'resend-invite',
}
