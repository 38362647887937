import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Button } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { EIconNames } from 'components/Base/Icon';
import useTable from 'components/Base/Table/hooks/useTable';
import {
  useGetPeoplesListQuery,
  useLazyGetPeoplesListQuery,
} from 'redux/apiSlice/people.slice';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { Table } from 'components/Base/Table';
import { EPeoplesColumnKey, TPeoplesDataUI } from 'data/types/people.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import CreateCoordinatorModal from 'components/Shared/Coordinator/CreateCoordinatorModal';
import { TCreateCoordinatorModalOpen } from 'components/Shared/Coordinator/CreateCoordinatorModal/CreateCoordinatorModal.types';
import {
  TPageableDataWithContentUI,
  TRtkErrorType,
} from 'data/types/generic.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import { ETagType } from 'data/types/tags.types';
import { Flex } from 'antd';
import { downloadCSV } from 'helpers/downloadCsv';
import { Alert } from 'components/Base/Alert';
import { getFormattedDateMDY } from 'utils/dateUtils';
import useGetColumnsData from './PeopleList.data';

const PeopleList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.people.list',
  });
  const { defaultColumns, defaultSortParam } = useGetColumnsData();
  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    columns,
    setColumns,
    hasFilters,
    onFiltersReset,
    selectedRows,
    setSelectedRows,
  } = useTable<TPeoplesDataUI>({
    storagePath: ETableStoragePaths.PEOPLE,
    defaultColumns,
    defaultSortColumn: defaultSortParam,
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [openAddPesronModal, setOpenAddPesronModal] =
    useState<TCreateCoordinatorModalOpen>({
      visible: false,
      initialName: '',
    });

  const { data: tagsFilters } = useGetTagsListQuery(ETagType.PEOPLE);

  const [getPeoplesList] = useLazyGetPeoplesListQuery();
  const { data, isFetching, isLoading, error } = useGetPeoplesListQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const onRowClick = (userId: string) => {
    navigate(`/app/people/${userId}`, {
      state: {
        backUrl: window.location.pathname,
        backButtonSuffix: t('TITLE'),
      } as TBackButtonState,
    });
  };

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === EPeoplesColumnKey.TAGS) {
          item.filters = tagsFilters;
        }
        return item;
      }),
    );
  }, [tagsFilters]);

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;

  useEffect(() => {
    if (selectedRows?.selectedRowsKeys?.length) {
      setShowAlert(false);
    }
  }, [selectedRows]);

  return (
    <>
      <PageWrapper
        title={t('TITLE')}
        actions={
          <Flex align="center" gap={16}>
            <Button
              onClick={() =>
                setOpenAddPesronModal({
                  visible: true,
                })
              }
              color="primary"
              type="primary"
            >
              {t('actions.add-person.ADD_PERSON_BTN')}
            </Button>
            <Button
              onClick={async () => {
                const { selectedRowsKeys } = selectedRows;

                if (!selectedRowsKeys.length) {
                  setShowAlert(true);
                  return;
                }

                const params = {
                  page: 0,
                  pageSize: 10000,
                  sortColumn: {
                    sortField: undefined,
                    sortDirection: undefined,
                  },
                };

                const peoplesList: TPageableDataWithContentUI<
                  TPeoplesDataUI[]
                > = await getPeoplesList(params).unwrap();

                const exportedRows =
                  peoplesList?.content.filter(row =>
                    selectedRowsKeys.includes(row.userId),
                  ) || [];

                const selectedRowsTransformed = exportedRows.map(row => {
                  if (row === undefined) return undefined;

                  const { fullName, title, phone, email, tags } = row;

                  return {
                    fullName,
                    title: title || '',
                    phone: phone || '',
                    email,
                    tags: tags?.map(tag => tag.text).join(', '),
                  };
                });

                const formattedDate = getFormattedDateMDY();

                downloadCSV(
                  ['Full Name', 'Title', 'Mobile', 'Email', 'Tags'],
                  selectedRowsTransformed,
                  record => [
                    `"${record.fullName}"`,
                    `"${record.title}"`,
                    `"${record.phone}"`,
                    `"${record.email}"`,
                    `"${record.tags}"`,
                  ],
                  `People_${formattedDate}.csv`,
                );
              }}
              color="primary"
              type="primary"
            >
              {t('actions.export.EXPORT_BTN')}
            </Button>
          </Flex>
        }
        isLoading={isLoading}
        error={error as TRtkErrorType}
        emptyWrapper={{
          isEmpty,
          alt: 'People',
          icon: EIconNames.PEOPLE_PROFILE_FILLED,
          btnText: t('empty-view.BTN_TEXT'),
          emptyText: t('empty-view.EMPTY_TEXT'),
          onClick: () =>
            setOpenAddPesronModal({
              visible: true,
            }),
        }}
      >
        <CardContent>
          {showAlert && (
            <div className="mx-4" style={{ minHeight: '1px' }}>
              <Alert
                type="warning"
                closable
                message={t('table.ACTION_ALERT_MESSAGE')}
                className="mt-4"
                onClose={() => setShowAlert(false)}
              />
            </div>
          )}
          <Table<TPeoplesDataUI>
            rowKey={(record: TPeoplesDataUI) => record.userId}
            currentPage={page}
            columns={columns}
            dataSource={data?.content}
            rowClassName="pointer"
            loading={isFetching}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalDataCount={data?.totalElements}
            onChange={handleTableChange}
            hasFilters={hasFilters}
            onRow={(record: TPeoplesDataUI) => ({
              onClick: () => onRowClick(record.userId),
            })}
            onFiltersReset={onFiltersReset}
            storagePath={ETableStoragePaths.PEOPLE}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </CardContent>
      </PageWrapper>
      <CreateCoordinatorModal
        open={openAddPesronModal}
        setOpen={setOpenAddPesronModal}
        label={t('actions.add-person.modal.TITLE')}
      />
    </>
  );
};

export default PeopleList;
