import { RadioGroup, Radio } from '@idaho-aeyc/ui-kit';
import { styled } from 'styled-components';

export const SRadioGroup = styled(RadioGroup)`
  && {
    .ant-radio-wrapper.ant-radio-wrapper-disabled {
      .ant-radio-disabled {
        .ant-radio-inner {
          background-color: #fff;
          border-color: #b5b5b5 !important;
        }

        &.ant-radio-checked .ant-radio-inner::after {
          background-color: #b5b5b5 !important;
        }
      }

      span.ant-radio + span {
        color: #808080 !important;
      }
    }

    .ant-radio-button-wrapper-disabled {
      color: #808080 !important;

      &.ant-radio-button-wrapper-checked {
        background-color: #f5f5f5;
        border-color: #b5b5b5 !important;

        &::before {
          background-color: #b5b5b5 !important;
        }
      }
    }
  }
`;

export const SRadio = styled(Radio)`
  &&.ant-radio-wrapper.ant-radio-wrapper-disabled {
    .ant-radio-disabled {
      .ant-radio-inner {
        background-color: #fff;
        border-color: #b5b5b5 !important;
      }

      &.ant-radio-checked .ant-radio-inner::after {
        background-color: #b5b5b5 !important;
      }
    }

    span.ant-radio + span {
      color: #808080 !important;
    }
  }
`;
