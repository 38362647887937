import { Text, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import { TAdminsDataUI } from 'data/types/admin.types';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import dayjs from 'dayjs';
import { EIconNames, Icon, Tooltip } from '@idaho-aeyc/ui-kit';
import { Flex } from 'antd';
import { EUserStatus, EUserType } from '../../../../data/types/user.types';
import StatusButton from '../Components/StatusButton';
import useGetTableColumnData from '../../../../components/Base/Table/components/SearchFilter';
import { useResendInviteMutation } from '../../../../redux/apiSlice/userGrow.slice';

const ELC_PM_TOOLTIP: Record<EUserStatus, string> = {
  PENDING: 'The invitation to GROW! has not been accepted',
  ACTIVE: 'The user is an ELC Project Manager on at least one ELC',
  IDLE: 'User currently is not assigned to workshops that are in the planning or published state',
  INACTIVE: 'The user is NOT assigned as an ELC Project Manager',
  EXPIRED: 'GROW! Invitation has expired.',
};

const SITE_COORDINATOR_TOOLTIP: Record<EUserStatus, string> = {
  PENDING: 'The invitation to GROW! has not been accepted',
  ACTIVE:
    'The user is a site coordinator on at least 1 workshop that is not closed or cancelled',
  IDLE: 'User currently is not assigned to workshops that are in the planning or published state',
  INACTIVE: 'The user is NOT assigned as a Site Coordinator',
  EXPIRED: 'GROW! Invitation has expired.',
};

const useGetTeamTableData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table.title',
  });
  const { t: statusPrefix } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table.status',
  });

  const [resendInvite] = useResendInviteMutation();

  const { getTableColumnSearchProps } = useGetTableColumnData();

  const onHandleResendInvite = async (
    status: EUserStatus,
    userId: string,
    profileType: EUserType,
  ) => {
    if (
      (status === EUserStatus.PENDING || status === EUserStatus.EXPIRED) &&
      userId
    ) {
      await resendInvite({
        userId,
        profileType,
      });
    }
  };

  const CURRENT_ANIMALS_COLUMNS: TColumnType<TAdminsDataUI>[] = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (_, record) => record?.firstName,
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (_, record) => record?.lastName,
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: 'email',
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (text, record) => {
        return <CopyTextButton title={record.email} />;
      },
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (_, record) => record?.phone,
    },
    {
      title: t('ELC_PM'),
      dataIndex: 'elcPMstatus',
      key: 'elcPMstatus',
      filters: ['PENDING', 'ACTIVE', 'INACTIVE', 'EXPIRED'].map(status => ({
        text: statusPrefix(status),
        value: status,
      })),
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (_, record) => {
        const user = record.userProfileStatus?.find(
          userProfile => userProfile.userProfileType === EUserType.ELC_PM,
        );

        if (!user || user.status === null) return null;

        const status = user.status as EUserStatus;

        return (
          <Flex gap={8} align="center">
            <Tooltip title={ELC_PM_TOOLTIP[status]} placement="topLeft">
              <div>
                <StatusButton
                  status={status}
                  label={statusPrefix(status)}
                  style={{ width: 102 }}
                />
              </div>
            </Tooltip>
            {(status === EUserStatus.PENDING ||
              status === EUserStatus.EXPIRED) && (
              <Tooltip title="Resend Invitation" placement="topLeft">
                <Flex
                  onClick={() =>
                    onHandleResendInvite(
                      status,
                      record.adminId,
                      EUserType.ELC_PM,
                    )
                  }
                >
                  <Icon
                    icon={EIconNames.EMAIL_OUTLINED_ARROW}
                    alt="email icon"
                    color="#1b1a1c"
                    size={24}
                  />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: t('SITE_COORDINATOR'),
      dataIndex: 'siteCoordinatorStatus',
      key: 'siteCoordinatorStatus',
      filters: ['PENDING', 'ACTIVE', 'INACTIVE', 'IDLE', 'EXPIRED'].map(
        status => ({
          text: statusPrefix(status),
          value: status,
        }),
      ),
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (_, record) => {
        const user = record.userProfileStatus?.find(
          userProfile =>
            userProfile.userProfileType === EUserType.SITE_COORDINATOR,
        );

        if (!user || user.status === null) return null;

        const status = user.status as EUserStatus;

        return (
          <Flex gap={8} align="center">
            <Tooltip
              title={SITE_COORDINATOR_TOOLTIP[status]}
              placement="topLeft"
            >
              <div>
                <StatusButton
                  status={status}
                  label={statusPrefix(status)}
                  style={{ width: 102 }}
                />
              </div>
            </Tooltip>
            {(status === EUserStatus.PENDING ||
              status === EUserStatus.EXPIRED) && (
              <Tooltip title="Resend Invitation" placement="topLeft">
                <Flex
                  onClick={() =>
                    onHandleResendInvite(
                      status,
                      record.adminId,
                      EUserType.SITE_COORDINATOR,
                    )
                  }
                >
                  <Icon
                    icon={EIconNames.EMAIL_OUTLINED_ARROW}
                    alt="email icon"
                    color="#1b1a1c"
                    size={24}
                  />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: t('LAST_LOGIN'),
      dataIndex: 'last-login',
      key: 'last-login',
      render: (text, record) => (
        <Text>
          {record.lastLoggedInDate
            ? dayjs(record.lastLoggedInDate).format('MMM DD, YYYY h:mmA')
            : '-'}
        </Text>
      ),
    },
  ];
  return { defaultColumns: CURRENT_ANIMALS_COLUMNS };
};

export default useGetTeamTableData;
