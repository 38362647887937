/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from '@packages/utils';
import { capitalize } from 'lodash';
import { TColumnType } from 'components/Base/Table/Table.types';
import { Col, Row } from 'components/Base/Grid';
import { Flex, Tooltip } from 'antd';
import {
  ERegistrantsColumnKey,
  TRegistrantUI,
} from 'data/types/registrants.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import { ParagraphEllipsis } from 'components/Shared/ParagraphEllipsis';
import Button from 'components/Base/Button/Button';
import { formatPhoneDisplay } from 'helpers/phoneUtils';
import { EditPencilOutline, PersonRemoveOutline } from 'assets/icon/icon';
import { ANSWER_MAX_LENGTH } from '../../WaitlistTab/WorkshopWaitList/WorkshopWaitList.data';
import { TFilterRegistrantsColumns } from './RegistrantsList.types';

const useGetRegistrantsColumnsData = (
  handleEditRegistrant: (record: TRegistrantUI) => void,
  handleDeleteClick: (record: TRegistrantUI) => void,
) => {
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.table.title',
  });

  const workshopRegistrantsColumns: TColumnType<TRegistrantUI>[] = [
    {
      title: t('REGISTRATION_DATE'),
      dataIndex: 'createdDate',
      key: 'creationDate',
      sorter: true,
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
    },
    {
      title: 'Registrant Name',
      dataIndex: 'fullName',
      key: 'registrantFullName',
      sorter: true,
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
      ...getTableColumnSearchProps<TRegistrantUI>(),
    },
    {
      title: 'Registrant Mobile',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      onCell: () => ({
        style: { width: '180px' },
      }),
    },
    {
      title: 'Registrant Email',
      dataIndex: 'email',
      key: 'email',
      onCell: () => ({
        className: 'custom-cell',
        style: { minWidth: '250px' },
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => {
        return <CopyTextButton title={record.email} />;
      },
    },
    {
      title: 'Additional Adult',
      dataIndex: 'additionalAdult',
      key: 'additionalAdultFullName',
      sorter: true,
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
      ...getTableColumnSearchProps<TRegistrantUI>(),
      render: (text, record) => {
        return <>{record.additionalAdults[0].fullName}</>;
      },
    },
    {
      title: 'Adult Mobile',
      dataIndex: 'additionalAdult',
      key: 'additionalAdultMobile',
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
      render: (text, record) => {
        return <>{formatPhoneDisplay(record.additionalAdults[0].phone)}</>;
      },
    },
    {
      title: 'Adult Email',
      dataIndex: 'additionalAdult',
      key: 'additionalAdultEmail',
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
      render: (text, record) => {
        return <CopyTextButton title={record.additionalAdults[0].email} />;
      },
    },
    {
      title: '# of Child(ren)',
      dataIndex: 'childrenNames',
      key: 'childCount',
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
      render: childrenNames => {
        return <>{childrenNames?.length || 0}</>;
      },
    },
    {
      title: t('CHILDREN_NAMES'),
      dataIndex: 'childrenNames',
      key: 'childrenNames',
      render: (text, record) => (
        <Row gutter={[0, 8]}>
          {record?.childrenNames?.map(name => (
            <Col key={name} span={24}>
              {name}
            </Col>
          ))}
        </Row>
      ),
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
    },
    {
      title: t('AGE_GROUP'),
      dataIndex: 'ageGroup',
      key: ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP,
      filters: [],
      filterMode: 'tree',
      filterMultiple: true,
      onCell: () => ({
        style: { minWidth: '140px' },
      }),
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },
    {
      title: t('QUESTION_ANSWER'),
      dataIndex: 'customQuestionAnswer',
      key: 'customQuestionAnswer',
      render: answer => {
        return (
          <ParagraphEllipsis
            text={answer}
            textMaxLength={ANSWER_MAX_LENGTH}
            fontFamily="Montserrat"
          />
        );
      },
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
    },
    {
      title: 'Kits',
      key: 'orientationKit',
      onCell: () => ({
        style: { minWidth: '140px' },
      }),
      render: (text, record) => {
        return (
          <>
            {capitalize(record.materialsLanguage.slice(0, 3))}{' '}
            {record.childrenNames.length}
          </>
        );
      },
    },
    {
      title: 'Binder',
      key: 'binderReceived',
      render: (text, record) => {
        return <>{record.orientationKit?.requested ? 'Yes' : 'No'}</>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'attendances',
      key: 'attendances',
      fixed: 'right',
      width: 280,
      onCell: record => ({
        className: record.hasUpdatableAttendance ? 'custom-cell' : '',
        style: {
          borderSpacing: 'initial',
        },
      }),
      render: () => null,
    },
  ];

  return { workshopRegistrantsColumns };
};

const filterRegistrantsColumns = ({
  customQuestion,
  hideChildInfo,
  columns,
  setColumns,
}: TFilterRegistrantsColumns) => {
  if (!customQuestion || hideChildInfo) {
    const filteredColumns = columns.filter(col => {
      if (!customQuestion && col.dataIndex === 'customQuestionAnswer') {
        return false;
      }
      if (
        hideChildInfo &&
        (col.dataIndex === 'countOfChildren' ||
          col.dataIndex === 'childrenNames')
      ) {
        return false;
      }
      return true;
    });
    setColumns(filteredColumns);
  }
};

export { filterRegistrantsColumns };

export default useGetRegistrantsColumnsData;
