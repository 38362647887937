import { Image, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useUpdateAttendanceMutation } from 'redux/apiSlice/registrants.slice';
import { ESortDirection, TRtkErrorType } from 'data/types/generic.types';
import {
  EAttendanceColumnKey,
  EAttendanceStatus,
  ERegistrantsColumnKey,
  TAttendanceEditUI,
  TAttendanceListUI,
} from 'data/types/registrants.types';
import photographyAllowed from 'assets/images/photographyAllowed.svg';
import photographyNotAllowed from 'assets/images/photographyNotAllowed.svg';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { Flex } from 'antd';
import { Switch } from 'components/Base/Switch';
import {
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
  EWorkshopStatus,
} from 'data/types/workshops.types';
import ButtonGroup from 'components/Base/ButtonGroup/ButtonGroup';
import { useAppSelector } from 'redux/store';
import { useMemo } from 'react';
import { EUserType } from 'data/types/user.types';
import PlusMinusInput from 'components/Base/PlusMinusInput/PlusMinusInput';

const ADMIN_TYPES = [EUserType.READY_ADMIN, EUserType.SUPER_ADMIN];

const useGetData = ({
  workshopId,
  workshopStatus,
  workshopType,
}: {
  workshopId: string;
  workshopStatus?: EWorkshopStatus;
  workshopType?: EWorkshopStandaloneType | EWorkshopSeriesType;
}) => {
  const usersData = useAppSelector(state => state.userSlice);
  const { user } = usersData;

  const isAdmin = useMemo(() => {
    return user?.type?.some((type: EUserType) => ADMIN_TYPES.includes(type));
  }, [user]);

  const { t } = useTranslation();

  const { getTableColumnSearchProps } = useGetTableColumnData();
  const defaultSortParam = {
    sortField: EAttendanceColumnKey.LAST_NAME,
    sortDirection: ESortDirection.descend,
  };

  const { showToastNotification } = useShowToastNotification();

  const [updateAttendance] = useUpdateAttendanceMutation();

  const isOutsideEditableTimeWindow = (sessionDateTime: string) => {
    // always enable the button
    if (isAdmin) return false;

    const now = new Date();
    const sessionDate = new Date(sessionDateTime);
    const fourHoursBefore = new Date(
      sessionDate.getTime() - 4 * 60 * 60 * 1000,
    );
    const fortyEightHoursAfter = new Date(
      sessionDate.getTime() + 48 * 60 * 60 * 1000,
    );

    // Return true if outside editable window:
    // 1. More than 4 hours before the session OR
    // 2. More than 48 hours after the session start
    return now < fourHoursBefore || now > fortyEightHoursAfter;
  };

  const isUpdatable = (record: TAttendanceListUI): boolean => {
    const currentAttendance = record.attendances.find(
      attendance =>
        attendance.sessionAttendanceId === record.currentAttendanceSessionId,
    );

    return currentAttendance?.isUpdatable || false;
  };

  const isDisabled = (record: TAttendanceListUI): boolean => {
    return (
      !isUpdatable(record) ||
      isOutsideEditableTimeWindow(record.sessionDateTime || '') ||
      workshopStatus === EWorkshopStatus.CLOSED ||
      workshopStatus === EWorkshopStatus.CANCELLED
    );
  };

  const handleSubmit = async ({
    registrantId,
    attendances,
    orientationKitReceived,
  }: {
    registrantId: string;
    attendances?: TAttendanceEditUI[];
    orientationKitReceived: boolean;
  }) => {
    try {
      await updateAttendance({
        attendances: attendances || [],
        registrantId,
        workshopId,
        orientationKitReceived,
      }).unwrap();
      showToastNotification({
        message: t(
          'descriptions.admin-portal.workshops.details.workshop-registrants.actions.edit.modal.SUCCESS_MESSAGE',
        ),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const handleAttendanceUpdate = async (
    record: TAttendanceListUI,
    updates: {
      attendanceStatus?: EAttendanceStatus;
      kitCount?: number;
      orientationKitReceived?: boolean;
    },
  ) => {
    const currentAttendance = record.attendances.find(
      attendance =>
        attendance.sessionAttendanceId === record.currentAttendanceSessionId,
    );

    if (!currentAttendance) return;

    const updatedAttendance = {
      attendanceId: currentAttendance.sessionAttendanceId,
      attendanceStatus:
        updates.attendanceStatus || currentAttendance.attendanceStatus,
      kitCount: updates.kitCount ?? currentAttendance.kitCount ?? 0,
      sessions: record.sessions || [],
    };

    await handleSubmit({
      registrantId: record.registrantId,
      attendances: [updatedAttendance],
      orientationKitReceived:
        updates.orientationKitReceived ?? record.binders.received,
    });
  };

  const defaultColumns: TColumnType<TAttendanceListUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.FIRST_NAME',
      ),
      dataIndex: 'firstName',
      key: EAttendanceColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TAttendanceListUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.LAST_NAME',
      ),
      dataIndex: 'lastName',
      key: EAttendanceColumnKey.LAST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TAttendanceListUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.PHOTO',
      ),
      dataIndex: 'photo',
      key: EAttendanceColumnKey.PHOTO,
      sorter: true,
      render: (_, record) => (
        <div className="pl-2">
          {record.photo ? (
            <Image src={photographyAllowed} height={24} alt="" />
          ) : (
            <Image src={photographyNotAllowed} height={24} alt="" />
          )}
        </div>
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.CHILDREN_NAMES',
      ),
      dataIndex: 'childrenNames',
      key: 'childrenNames',
      render: (_, record) => <p>{record?.childrenNames?.join(', ')}</p>,
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.AGE_GROUP',
      ),
      dataIndex: 'ageGroup',
      key: ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP,
      filters: [],
      filterMode: 'tree',
      filterMultiple: true,
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.ATTENDANCE',
      ),
      dataIndex: 'attendance',
      key: 'attendance',
      render: (_, record) => (
        <ButtonGroup
          options={[
            {
              label: 'Attended',
              value: EAttendanceStatus.ATTENDED,
            },
            {
              label: 'Absent',
              value: EAttendanceStatus.ABSENT,
            },
          ]}
          value={record.attendance || EAttendanceStatus.ATTENDED}
          size="small"
          disabled={isDisabled(record)}
          onChange={async value => {
            if (record.attendance === value) return;
            await handleAttendanceUpdate(record, {
              attendanceStatus: value as EAttendanceStatus,
            });
          }}
        />
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.KITS',
      ),
      dataIndex: 'kits',
      key: 'kits',
      render: (_, record) => (
        <Flex gap={30} align="center">
          <div className="pl-2">{record.countOfChildren}</div>
          <PlusMinusInput
            value={record.kits}
            min={0}
            max={record.countOfChildren}
            disabled={
              isDisabled(record) ||
              record.attendance === EAttendanceStatus.ABSENT
            }
            onChange={async value => {
              await handleAttendanceUpdate(record, { kitCount: value });
            }}
          />
        </Flex>
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.BINDERS',
      ),
      dataIndex: 'binders',
      key: 'binders',
      render: (_, record) => {
        const notLabel =
          workshopType === EWorkshopSeriesType.READY_FOR_KINDERGARTEN
            ? 'Not Needed'
            : 'Not Required';

        return (
          <>
            {record.binders.requested ? (
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                disabled={
                  isDisabled(record) ||
                  record.attendance === EAttendanceStatus.ABSENT
                }
                onChange={async () => {
                  await handleAttendanceUpdate(record, {
                    orientationKitReceived: !record.binders.received,
                  });
                }}
                checked={record.binders.received}
              />
            ) : (
              <>{notLabel}</>
            )}
          </>
        );
      },
    },
    {
      title: ' ',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: () => null,
    },
  ];

  return { defaultColumns, defaultSortParam };
};

export default useGetData;
