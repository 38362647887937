import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputField, InputWrapper } from './PlusMinusInput.styles';

interface PlusMinusInputProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  width?: number | string;
  disabled?: boolean;
}

const PlusMinusInput: React.FC<PlusMinusInputProps> = ({
  value,
  onChange,
  min = 0,
  max = Infinity,
  step = 1,
  width,
  disabled = false,
}) => {
  const handleIncrement = () => {
    if (disabled || value >= max) return;
    onChange(Math.min(value + step, max));
  };

  const handleDecrement = () => {
    if (disabled || value <= min) return;
    onChange(Math.max(value - step, min));
  };

  return (
    <InputWrapper $width={width}>
      <Button
        $disabled={disabled || value <= min}
        disabled={disabled || value <= min}
        onClick={handleDecrement}
        type="button"
        aria-label="Decrease value"
      >
        <MinusOutlined size={18} />
      </Button>
      <InputField>{value}</InputField>
      <Button
        $disabled={disabled || value >= max}
        disabled={disabled || value >= max}
        onClick={handleIncrement}
        type="button"
        aria-label="Increase value"
      >
        <PlusOutlined size={18} />
      </Button>
    </InputWrapper>
  );
};

export default PlusMinusInput;
