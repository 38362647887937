import { useTranslation } from '@packages/utils';
import { EIconNames, Icon, TMenuProps, Tooltip } from '@idaho-aeyc/ui-kit';
import { EUserStatus, EUserType } from 'data/types/user.types';
import { ButtonDropdown } from 'components/Base/ButtonDropdown';
import { Flex } from 'antd';
import StatusButton from '../../Components/StatusButton';
import {
  useResendInviteMutation,
  useUpdateUserProfileMutation,
} from '../../../../../redux/apiSlice/userGrow.slice';

const ADMIN_TOOLTIP: Record<EUserStatus, string> = {
  PENDING: 'The invitation to GROW! has not been accepted',
  ACTIVE: 'The user is either a Lead or RFK Admin',
  IDLE: 'User is not assigned to this role',
  INACTIVE: 'User is not assigned to this role',
  EXPIRED: 'GROW! Invitation has expired.',
};

type UserStatusDropdownProps = {
  user?: {
    userId: string;
    profileType: EUserType;
  };
  value: EUserStatus;
  options: EUserStatus[];
};

const UserStatusDropdown = ({
  user,
  value,
  options,
}: UserStatusDropdownProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table.status',
  });

  const [resendInvite] = useResendInviteMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const handleMenuClick = (option: EUserStatus) => async () => {
    if (option === EUserStatus.ACTIVE && user) {
      await updateUserProfile({
        userId: user?.userId,
        profileType: user?.profileType,
      });
    }
  };

  const onHandleResendInvite = async (status: EUserStatus) => {
    if (
      (status === EUserStatus.PENDING || status === EUserStatus.EXPIRED) &&
      user
    ) {
      await resendInvite({
        userId: user.userId,
        profileType: user.profileType,
      });
    }
  };

  const items: TMenuProps['items'] = options.map(option => ({
    label: (
      <StatusButton
        label={t(option)}
        status={option}
        style={{ width: '100%' }}
      />
    ),
    key: option,
    onClick: handleMenuClick(option),
  }));

  return (
    <Flex gap={8} align="center">
      <Tooltip title={ADMIN_TOOLTIP[value]} placement="topLeft">
        <div>
          <StatusButton
            label={t(value)}
            status={value}
            style={{ width: 102 }}
          />
        </div>
      </Tooltip>
      {value !== EUserStatus.PENDING && value !== EUserStatus.EXPIRED ? (
        <ButtonDropdown
          buttonProps={{
            children: (
              <Icon
                icon={EIconNames.ARROW_FILLED_DOWN}
                alt="arrow down icon"
                color="#1b1a1c"
                size={16}
              />
            ),
            color: 'primary',
            style: {
              border: '0',
              background: 'none',
              padding: 0,
            },
          }}
          dropdownProps={{
            menu: {
              items,
            },
            rootClassName: 'button-dropdown',
            placement: 'bottomRight',
          }}
        />
      ) : (
        <Tooltip title="Resend Invitation" placement="topLeft">
          <Flex
            onClick={async e => {
              e.stopPropagation();
              await onHandleResendInvite(EUserStatus.PENDING);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              icon={EIconNames.EMAIL_OUTLINED_ARROW}
              alt="email icon"
              color="#1b1a1c"
              size={24}
            />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default UserStatusDropdown;
