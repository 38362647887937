import { Text, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import { TAdminsDataUI } from 'data/types/admin.types';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import dayjs from 'dayjs';
import { EUserStatus, EUserType } from '../../../../data/types/user.types';
import useGetTableColumnData from '../../../../components/Base/Table/components/SearchFilter';
import UserStatusDropdown from '../TeamAction/UserStatusDropdown';

const ELC_LEAD_STATUSES: EUserStatus[] = [
  EUserStatus.ACTIVE,
  EUserStatus.INACTIVE,
];

const RFK_ADMIN_STATUSES: EUserStatus[] = [
  EUserStatus.ACTIVE,
  EUserStatus.INACTIVE,
];

const useGetTeamTableData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table.title',
  });
  const { t: statusPrefix } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table.status',
  });

  const { getTableColumnSearchProps } = useGetTableColumnData();

  const ADMIN_COLUMNS: TColumnType<TAdminsDataUI>[] = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (_, record) => record?.firstName,
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (_, record) => record?.lastName,
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: 'email',
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (text, record) => {
        return <CopyTextButton title={record.email} />;
      },
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      ...getTableColumnSearchProps<TAdminsDataUI>(),
      render: (_, record) => record?.phone,
    },
    {
      title: t('AEYC_LEAD'),
      dataIndex: 'elcLeadStatus',
      key: 'elcLeadStatus',
      filters: ['PENDING', 'ACTIVE', 'INACTIVE', 'EXPIRED'].map(status => ({
        text: statusPrefix(status),
        value: status,
      })),
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (_, record) => {
        const user = record.userProfileStatus?.find(
          userProfile => userProfile.userProfileType === EUserType.ELC_LEAD,
        );

        if (!user || user.status === null) return null;

        return (
          <UserStatusDropdown
            user={{
              userId: record.adminId,
              profileType: EUserType.ELC_LEAD,
            }}
            value={user.status as EUserStatus}
            options={ELC_LEAD_STATUSES}
          />
        );
      },
    },
    {
      title: t('RFK_ADMIN'),
      dataIndex: 'readyAdminStatus',
      key: 'readyAdminStatus',
      filters: ['PENDING', 'ACTIVE', 'INACTIVE', 'EXPIRED'].map(status => ({
        text: statusPrefix(status),
        value: status,
      })),
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (_, record) => {
        const user = record.userProfileStatus?.find(
          userProfile => userProfile.userProfileType === EUserType.READY_ADMIN,
        );

        if (!user || user.status === null) return null;

        return (
          <UserStatusDropdown
            user={{
              userId: record.adminId,
              profileType: EUserType.READY_ADMIN,
            }}
            value={user.status as EUserStatus}
            options={RFK_ADMIN_STATUSES}
          />
        );
      },
    },
    {
      title: t('LAST_LOGIN'),
      dataIndex: 'last-login',
      key: 'last-login',
      render: (text, record) => (
        <Text>
          {record.lastLoggedInDate
            ? dayjs(record.lastLoggedInDate).format('MMM DD, YYYY h:mmA')
            : '-'}
        </Text>
      ),
    },
  ];
  return { defaultColumns: ADMIN_COLUMNS };
};

export default useGetTeamTableData;
