import { Modal } from 'antd';
import { styled } from 'styled-components';

export const SModal = styled(Modal)`
  .ant-modal-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
`;
