import dayjs from 'dayjs';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { TPartsUI } from 'data/types/workshops.types';
import { formatStringToTitleCase } from 'helpers/utils';
import { TabBodyWrapper } from 'components/Base/Tabs';
import AttendanceList from './AttendanceList';
import { TAttendanceListTabProps } from './AttendanceTab.types';

const AttendanceTab = ({ data }: TAttendanceListTabProps) => {
  const sessions = data?.parts || [];

  const shouldShow = (date: string) => {
    const sessionDate = dayjs(date);
    const fourHoursBefore = sessionDate.subtract(4, 'hours');
    return dayjs().isAfter(fourHoursBefore);
  };

  const renderAttendance = (session: TPartsUI) => {
    return (
      <div key={session.partType}>
        <CardContent tabContent contentPadding="8px 0px">
          <AttendanceList
            title={`${formatStringToTitleCase(session.partType)} - ${
              session.dateTime?.value
            }`}
            sessionDateTime={session.dateTime?.value}
            workshopId={data?.id || ''}
            workshopStatus={data?.status}
            workshopType={data?.type}
            customQuestion={data?.customQuestion.value}
            csvFileNamePrefix={data?.location?.name || data?.host?.name}
            hideChildInfo={!!data?.isCCP}
            partType={session.partType}
            sessions={sessions}
          />
        </CardContent>
      </div>
    );
  };

  const items: TabsProps['items'] = [];

  sessions?.forEach((session, index) => {
    if (shouldShow(session.dateTime?.value || '') || index === 0) {
      items.push({
        key: index.toString(),
        label: `${formatStringToTitleCase(session.partType)} • ${
          session.dateTime?.value
        }`,
        children: renderAttendance(session),
      });
    }
  });

  if (!data) return null;

  return (
    <TabBodyWrapper isEmpty={!items.length} emptyText="No Attendance data yet.">
      <Tabs
        defaultActiveKey="0"
        items={items}
        tabBarStyle={{ margin: '-16px -16px 0 -16px' }}
      />
    </TabBodyWrapper>
  );
};

export default AttendanceTab;
