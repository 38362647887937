import { EWorkshopChildcareAgeGroups, TChildcareItem } from '@packages/utils';
import { TAgeGroupColumnItem } from '../../components/Shared/AgeGroupesColumn/AgeGroupesColumn.types';
import { EAgeGroup, EWorkshopLanguage, TTableFilters } from './generic.types';
import {
  EWorkshopCategory,
  EWorkshopPartType,
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
  TPartsUI,
} from './workshops.types';
import { TTagDB, TTagUI } from './tags.types';

export enum ERegitrantStatus {
  ACTIVE = 'ACTIVE',
  UNREGISTERED = 'UNREGISTERED',
}

export enum EAttendanceStatus {
  ATTENDED = 'ATTENDED',
  ABSENT = 'ABSENT',
  E_LEARNING = 'E_LEARNING',
}
export enum ERegistrantsColumnKey {
  CREATION_DATE = 'creationDate',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  AGE_GROUP = 'ageGroups',
  LANGUAGE = 'languages',
  FILTER_BY_LANGUAGE_AND_AGE_GROUP = 'FILTER_BY_LANGUAGE_AND_AGE_GROUP',
  REGISTRANT_FULL_NAME = 'registrantFullName',
  ADDITIONAL_ADULT_FULL_NAME = 'additionalAdultFullName',
}

export enum EWaitListColumnKey {
  CREATION_DATE = 'creationDate',
  AGE_GROUP = 'ageGroups',
  TYPE = 'workshopTypes',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CITIES = 'cities',
  LOCATION = 'locationIds',
  TAGS = 'tagIds',
}

export enum EAttendanceColumnKey {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHOTO = 'photo',
  CHILDREN = 'children',
  AGE_GROUP = 'ageGroups',
  ATTENDANCE = 'attendance',
  KITS = 'kits',
  BINDERS = 'binders',
}

export enum EChildcareColumnKey {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export type TOrientationKitUI = {
  requested: boolean;
  received: boolean;
};

export type TAttendancesGetDB = {
  sessionAttendanceId: string;
  attendanceStatus: EAttendanceStatus;
  kitCount: number | null;
  sessionId: string;
  partType: EWorkshopPartType;
  isUpdatable: boolean;
};

export type TAttendancesGetUI = {
  sessionAttendanceId: string;
  attendanceStatus: EAttendanceStatus;
  kitCount: number | null;
  sessionId: string;
  partType: EWorkshopPartType;
  isUpdatable: boolean;
};

export type TRegistrantDB = {
  isWorkshopClosed?: boolean | undefined;
  hasAttendedWorkshop?: boolean | undefined;
  registrantId: string;
  lastName: string;
  firstName: string;
  email?: string;
  phone: string;
  createdDate: string;
  countOfChildren: number;
  childrenNames: string[];
  attendances?: TAttendancesGetDB[];
  orientationKitReceived?: boolean;
  orientationKitRequested?: boolean;
  materialsLanguage: EWorkshopLanguage;
  language: EWorkshopLanguage;
  ageGroup: EAgeGroup;
  customQuestionAnswer?: string;
  workshopClassId: string;
  status: ERegitrantStatus;
  allowedTakePicture: boolean | null;
  nonDeleteReason?: string;
  additionalAdults: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    fullName: string;
  }[];
};

export type TRegistrantUI = {
  registrantId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email?: string;
  phone: string;
  createdDate: string;
  countOfChildren: number;
  childrenNames: string[];
  attendances: TAttendancesGetUI[];
  hasUpdatableAttendance?: boolean;
  orientationKit?: TOrientationKitUI;
  materialsLanguage: EWorkshopLanguage;
  spanishAgeGroups: TAgeGroupColumnItem[];
  englishAgeGroups: TAgeGroupColumnItem[];
  customQuestionAnswer?: string;
  workshopClassId: string;
  registrantStatus: ERegitrantStatus;
  allowedTakePicture: boolean | null;
  sessionDateTime?: string;
  status?: ERegitrantStatus;
  isWorkshopClosed?: boolean;
  hasAttendedWorkshop?: boolean;
  nonDeleteReason?: string;
  additionalAdults: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    fullName: string;
  }[];
};

export type TUpdateRegistrant = {
  workshopId: string;
  workshopClassId: string;
  registrantId: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  materialsLanguage: EWorkshopLanguage;
  childNames: string[];
  childcareRequirements: TChildCare[];
  hasReadyBinder?: boolean;
  additionalAdults: TAdditionalAdults[];
};

export type TAttendanceEditUI = {
  sessionAttendanceId?: string;
  sessionId?: string;
  attendanceId?: string;
  kitCount: number | null;
  attendanceStatus: EAttendanceStatus;
  partType?: EWorkshopPartType;
  isUpdatable?: boolean;
};

export type TAttendanceEditDB = {
  attendanceId: string;
  kitCount: number | null;
  attendanceStatus: EAttendanceStatus;
};

export type TAttendancesEditUI = {
  registrantId: string;
  attendances: TAttendanceEditUI[];
  orientationKitReceived?: boolean;
  workshopId: string;
};

export type TAttendancesEditDB = {
  workshopId: string;
  registrantId: string;
  attendances: TAttendanceEditDB[];
  orientationKitReceived?: boolean;
};

export type TAttendanceListUI = {
  firstName: string;
  lastName: string;
  photo: boolean | null;
  childrenNames: string[];
  spanishAgeGroups: TAgeGroupColumnItem[];
  englishAgeGroups: TAgeGroupColumnItem[];
  attendance: EAttendanceStatus;
  kits: number;
  binders: TOrientationKitUI;
  countOfChildren: number;
  registrantId: string;
  attendances: TAttendanceEditUI[];
  currentAttendanceSessionId?: string;
  sessionDateTime?: string;
  sessions?: TPartsUI[];
};

export type TWaitListUI = {
  registrantId: string;
  lastName: string;
  firstName: string;
  workshopTypeCategory: EWorkshopCategory;
  email: string;
  phone: string;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  spanishAgeGroups: TAgeGroupColumnItem[];
  englishAgeGroups: TAgeGroupColumnItem[];
  attendances: TAttendancesGetUI[];
  createdDate: string;
  childrenNames: string[];
  countOfChildren: number;
  city: string;
  workshopClassId: string;
  customQuestionAnswer?: string;
  location: string | null;
  workshopId: string;
  tags: TTagUI[];
};

export type TWaitListDB = {
  registrantId: string;
  language: EWorkshopLanguage;
  lastName: string;
  firstName: string;
  workshopTypeCategory: EWorkshopCategory;
  email: string;
  phone: string;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  ageGroup: EAgeGroup;
  attendances: TAttendancesGetDB[];
  createdDate: string;
  childrenNames: string[];
  countOfChildren: number;
  city: string;
  workshopClassId: string;
  customQuestionAnswer?: string;
  location: string | null;
  workshopId: string;
  tags: TTagDB[];
};

export type TWaitListMoveUI = {
  registrantId: string;
  workshopClassId: string;
  workshopId?: string;
};
export type TWaitListMoveDB = {
  registrantId: string;
  workshopClassId: string;
  workshopId?: string;
};
export interface TWaitListQueryParams
  extends TTableFilters<EWaitListColumnKey> {
  workshopId?: string;
  activeOnly?: boolean;
}

export type TRemoveRegistrantUI = {
  registrantId: string;
  workshopClassId: string;
  workshopId: string;
};
export type TRemoveRegistrantDB = {
  registrantId: string;
  workshopClassId: string;
  workshopId: string;
};

export type TRegisterRegistrantUI = {
  registrantId: string;
  workshopClassId: string;
  workshopId: string;
};
export type TRegisterRegistrantDB = {
  registrantId: string;
  workshopClassId: string;
  workshopId: string;
};

export type TChildcareRequirementsDB = {
  ageGroup: EWorkshopChildcareAgeGroups;
  count: number | null;
};

export type TRegistrantsWithChildcareDB = {
  lastName: string;
  firstName: string;
  workshopClassId: string;
  registrantId: string;
  childcareRequirements: TChildcareRequirementsDB[];
};

export type TRegistrantsWithChildcareUI = {
  lastName: string;
  firstName: string;
  workshopClassId: string;
  registrantId: string;
  childcareRequirements: TChildcareItem[];
};

export type TEditRegitrantChildcareUI = {
  registrantId: string;
  workshopClassId: string;
  childcareRequirements: TChildcareItem[];
  workshopId: string;
};
export type TEditRegitrantChildcareDB = {
  registrantId: string;
  workshopClassId: string;
  childcareRequirements: TChildcareRequirementsDB[];
  workshopId: string;
};

export type TRegistrantsNoChildcareDB = {
  lastName: string;
  firstName: string;
  workshopClassId: string;
  registrantId: string;
};

export type TRegistrantsNoChildcareUI = {
  fullName: string;
  workshopClassId: string;
  registrantId: string;
};

export type TAdditionalAdults = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type TChildCare = {
  ageGroup: EWorkshopChildcareAgeGroups;
  count: number | null;
};
