import {
  allowOperationsConverter,
  citiesFiltersConverter,
  exportCsvConverter,
  locationHostFilterDataConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import {
  attendancesEditConverter,
  getRegistrantConvertor,
  getWaitListConvertor,
  waitListFilterConverter,
  waitListMoveConverter,
  registrantsFilterConverter,
  registrantRemoveConverter,
  registrantRegisterConverter,
  childcareFilterConverter,
  getRegistrantsWithChildcareConvertor,
  editRegistrantsChildcareConvertor,
  getRegistrantsNoChildcareConvertor,
} from 'data/convertors/registrants.convertor';
import {
  TAllowedOperationsDB,
  TAllowedOperationsUI,
  TExportCsvDB,
  TExportCsvUI,
  TExportFilters,
  TLocationHostFilterDataDB,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import { TColumnFilterItem } from 'components/Base/Table/Table.types';
import {
  EWaitListColumnKey,
  TAttendancesEditUI,
  TRegistrantDB,
  TRegistrantUI,
  TRemoveRegistrantUI,
  TRegisterRegistrantUI,
  TWaitListDB,
  TWaitListMoveUI,
  TWaitListUI,
  TRegistrantsWithChildcareUI,
  EChildcareColumnKey,
  TRegistrantsWithChildcareDB,
  TEditRegitrantChildcareUI,
  TRegistrantsNoChildcareDB,
  TRegistrantsNoChildcareUI,
  ERegistrantsColumnKey,
  TUpdateRegistrant,
} from 'data/types/registrants.types';
import {
  ERegistrantsUrls,
  getWaitlistLocations,
  reRegisterRegistrantsUrl,
  registrantsChildcareUrl,
  registrantsNoChildcareUrl,
  removeRegistrantsUrl,
  unRegisterRegistrantsUrl,
  updateAttendanceUrl,
} from 'data/urls/registrants.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { downloadFile } from 'redux/services/fileServices';
import { workshopsApi } from './workshops.slice';
import { kitManagementApi } from './kitManagement.slice';

const baseUrl = `${ERegistrantsUrls.BASE_URL}`;

export const registrantsApi = authSplitApi('registrants', [
  'workshop-registrants',
  'wait-list',
  'wait-list-cities',
  'wait-list-locations',
  'registrants-with-childcare',
  'registrants-no-childcare',
]).injectEndpoints({
  endpoints: build => ({
    getRegistrants: build.query<
      TPageableDataWithContentUI<TRegistrantUI[]> & {
        allowedOperations: TAllowedOperationsUI;
      },
      TTableFilters & { workshopId?: string }
    >({
      query({
        workshopId,
        page,
        pageSize,
        filters,
        sortColumn: { sortDirection, sortField },
      }) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(workshopId && { workshopId }),
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && {
              ...registrantsFilterConverter(filters),
            }),
          },
        };
      },
      providesTags: (result, error, { workshopId }) => {
        if (workshopId) {
          return [{ type: 'workshop-registrants', id: workshopId }];
        }
        return [];
      },
      transformResponse: (
        data: TPageableDataWithContentDB<TRegistrantDB[]> & {
          allowedOperations: TAllowedOperationsDB;
        },
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            getRegistrantConvertor.fromDb(workshop),
          ),
          allowedOperations: allowOperationsConverter.fromDb(
            data.allowedOperations,
          ),
        };
      },
    }),
    updateAttendance: build.mutation<void, TAttendancesEditUI>({
      query(body) {
        return {
          url: updateAttendanceUrl,
          method: 'PATCH',
          body: attendancesEditConverter.toDb(body),
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags(['kit-management-parents']),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    getWaitList: build.query<
      TPageableDataWithContentUI<TWaitListUI[]> & {
        allowedOperations: TAllowedOperationsUI;
      },
      TTableFilters<EWaitListColumnKey> & {
        workshopId?: string;
        activeOnly?: boolean;
      }
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
        workshopId,
        activeOnly,
      }) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.WAIT_LIST}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(workshopId && { workshopId }),
            ...(activeOnly !== undefined && { activeOnly }),
            ...(filters && {
              ...waitListFilterConverter(filters),
            }),
          },
        };
      },
      providesTags: ['wait-list'],
      transformResponse: (
        data: TPageableDataWithContentDB<TWaitListDB[]> & {
          allowedOperations: TAllowedOperationsDB;
        },
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(waitList =>
            getWaitListConvertor.fromDb(waitList),
          ),
          allowedOperations: allowOperationsConverter.fromDb(
            data.allowedOperations,
          ),
        };
      },
    }),
    getWaitListCities: build.query<TColumnFilterItem[], void>({
      query() {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.WAIT_LIST}/${ERegistrantsUrls.CITIES}`,
          method: 'GET',
        };
      },
      providesTags: ['wait-list-cities'],
      transformResponse: (data: string[]) =>
        citiesFiltersConverter.fromDb(data),
    }),
    getValidateExport: build.query<boolean, { workshopId: string }>({
      query({ workshopId }) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.EXPORT}/${ERegistrantsUrls.VALIDATE}`,
          method: 'GET',
          params: { workshopId },
        };
      },
    }),
    exportRegistrantsCSV: build.query<
      TExportCsvUI,
      TExportFilters<ERegistrantsColumnKey> & { workshopId: string }
    >({
      query({ filters, workshopId }) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.EXPORT}`,
          method: 'GET',
          params: {
            ...(filters && {
              ...registrantsFilterConverter(filters),
            }),
            workshopId,
          },
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('text/csv').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    exportWaitlistCSV: build.query<TExportCsvUI, void>({
      query() {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.WAIT_LIST}/${ERegistrantsUrls.EXPORT}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('text/csv').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    getWaitListLocations: build.query<TColumnFilterItem[], void>({
      query() {
        return {
          url: getWaitlistLocations,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) => {
        return locationHostFilterDataConverter.fromDb(data);
      },
      providesTags: ['wait-list-locations'],
    }),
    moveAction: build.mutation<void, TWaitListMoveUI>({
      query(body) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.WAIT_LIST}/${ERegistrantsUrls.MOVE}`,
          method: 'PATCH',
          body: waitListMoveConverter.toDb(body),
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
        'wait-list-cities',
        'wait-list-locations',
        'wait-list',
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.endpoints.getWorkshopById.initiate({
                id: arg.workshopId || '',
              }),
            );
            dispatch(workshopsApi.util.invalidateTags(['workshop']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    removeRegistrant: build.mutation<void, TRemoveRegistrantUI>({
      query(body) {
        return {
          url: removeRegistrantsUrl,
          method: 'DELETE',
          body: registrantRemoveConverter.toDb(body),
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.util.invalidateTags(['workshops', 'workshop']),
            );
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-parents',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    unRegisterRegistrant: build.mutation<void, TRegisterRegistrantUI>({
      query(body) {
        return {
          url: unRegisterRegistrantsUrl,
          method: 'PATCH',
          body: registrantRegisterConverter.toDb(body),
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.util.invalidateTags(['workshops', 'workshop']),
            );
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
                'kit-management-parents',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    reRegisterRegistrant: build.mutation<void, TRegisterRegistrantUI>({
      query(body) {
        return {
          url: reRegisterRegistrantsUrl,
          method: 'PATCH',
          body: registrantRegisterConverter.toDb(body),
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.util.invalidateTags(['workshops', 'workshop']),
            );
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
                'kit-management-parents',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    getRegistrantsWithChildcare: build.query<
      TPageableDataWithContentUI<TRegistrantsWithChildcareUI[]> & {
        allowedOperations: TAllowedOperationsUI;
      },
      TTableFilters<EChildcareColumnKey> & {
        workshopId?: string;
      }
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
        workshopId,
      }) {
        return {
          url: registrantsChildcareUrl,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(workshopId && { workshopId }),
            ...(filters && {
              ...childcareFilterConverter(filters),
            }),
          },
        };
      },
      providesTags: ['registrants-with-childcare'],
      transformResponse: (
        data: TPageableDataWithContentDB<TRegistrantsWithChildcareDB[]> & {
          allowedOperations: TAllowedOperationsDB;
        },
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(childcare =>
            getRegistrantsWithChildcareConvertor.fromDb(childcare),
          ),
          allowedOperations: allowOperationsConverter.fromDb(
            data.allowedOperations,
          ),
        };
      },
    }),
    editChildcare: build.mutation<void, TEditRegitrantChildcareUI>({
      query(body) {
        return {
          url: registrantsChildcareUrl,
          method: 'PATCH',
          body: editRegistrantsChildcareConvertor.toDb(body),
        };
      },
      invalidatesTags: [
        'registrants-with-childcare',
        'registrants-no-childcare',
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.util.invalidateTags(['workshops-childCare-count']),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    getRegistrantsNoChildcare: build.query<
      TRegistrantsNoChildcareUI[],
      {
        workshopId?: string;
      }
    >({
      query({ workshopId }) {
        return {
          url: registrantsNoChildcareUrl,
          method: 'GET',
          params: {
            ...(workshopId && { workshopId }),
          },
        };
      },
      providesTags: ['registrants-no-childcare'],
      transformResponse: (data: TRegistrantsNoChildcareDB[]) =>
        (data || []).map(childcare =>
          getRegistrantsNoChildcareConvertor.fromDb(childcare),
        ),
    }),
    updateRegistrant: build.mutation<
      void,
      { workshopId: string; registrantId: string; workshopClassId: string }
    >({
      query(body: TUpdateRegistrant) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.util.invalidateTags(['workshops', 'workshop']),
            );
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-parents',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    deleteRegistrant: build.mutation<
      void,
      { workshopId: string; registrantId: string }
    >({
      query(body) {
        return {
          url: removeRegistrantsUrl,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (result, error, { workshopId }) => [
        { type: 'workshop-registrants', id: workshopId },
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              workshopsApi.util.invalidateTags(['workshops', 'workshop']),
            );
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-parents',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
  }),
});

export const {
  useGetRegistrantsQuery,
  useUpdateAttendanceMutation,
  useGetWaitListQuery,
  useGetWaitListCitiesQuery,
  useMoveActionMutation,
  useRemoveRegistrantMutation,
  useUnRegisterRegistrantMutation,
  useReRegisterRegistrantMutation,
  useGetWaitListLocationsQuery,
  useGetRegistrantsWithChildcareQuery,
  useGetRegistrantsNoChildcareQuery,
  useEditChildcareMutation,
  useGetValidateExportQuery,
  useLazyExportRegistrantsCSVQuery,
  useLazyExportWaitlistCSVQuery,
  useUpdateRegistrantMutation,
  useDeleteRegistrantMutation,
} = registrantsApi;
