import { authSplitApi } from 'redux/helpers/slice.helpers';
import { IAmplifyUser } from 'data/types/amplify.types';
import { EUserUrls } from 'data/urls/user.url';
import {
  EUserType,
  TAddNewUserUI,
  TAdminUserListDataDB,
  TAdminUserListDataUI,
  TDeleteUserUI,
  TUpdateUserUI,
  TUserDataDB,
  TUserDataUi,
  TUserIdentityDB,
  TUserIdentityUI,
  TUserListDataDB,
  TUserListDataUI,
} from 'data/types/user.types';
import {
  addNewUserConverter,
  adminUserListGetConverter,
  deleteUserConverter,
  getUserProfileConvertor,
  updateUserConverter,
  userIdentityGetConverter,
  userListFilterConverter,
  userListGetConverter,
} from 'data/convertors/user.convertors';
import {
  exportCsvConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import {
  TExportCsvDB,
  TExportCsvUI,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import { peopleApi } from './people.slice'; // eslint-disable-line
import { elcsApi } from './elcs.slice';
import { downloadFile } from '../services/fileServices';

const baseUrl = `${EUserUrls.BASE}`;

export const userGrowApi = authSplitApi('user', [
  'user',
  'users-list',
  'user-identity',
  'user-identity-no-profile',
]).injectEndpoints({
  endpoints: build => ({
    getUserProfile: build.query<TUserDataUi, { amplifyUser: IAmplifyUser }>({
      query() {
        return {
          url: `${baseUrl}/${EUserUrls.PROFILE}`,
          method: 'GET',
        };
      },
      providesTags: ['user'],
      transformResponse: (data: TUserDataDB) => {
        if (!data) {
          return null;
        }

        return getUserProfileConvertor.fromDb(data);
      },
    }),
    resendInvite: build.mutation<
      void,
      { userId: string; profileType: EUserType }
    >({
      query(body) {
        console.log('RESEND INVITE!');
        return {
          url: `${baseUrl}/${EUserUrls.RESEND_INVITE}`,
          method: 'PATCH',
          body: {
            userId: body.userId,
            profileType: body.profileType,
          },
        };
      },
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(userGrowApi.util.invalidateTags(['users-list']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateUserProfile: build.mutation<
      void,
      { userId: string; profileType: EUserType }
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EUserUrls.PROFILE}`,
          method: 'PATCH',
          body: {
            userId: body.userId,
            profileType: body.profileType,
          },
        };
      },
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(userGrowApi.util.invalidateTags(['users-list']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    deleteUserProfile: build.mutation<
      void,
      { userId: string; profileType: EUserType }
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EUserUrls.PROFILE}`,
          method: 'DELETE',
          body: {
            userId: body.userId,
            profileType: body.profileType,
          },
        };
      },
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(userGrowApi.util.invalidateTags(['users-list']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    userAcceptInvite: build.mutation<string, void>({
      query() {
        return {
          url: `${baseUrl}/${EUserUrls.ACCEPT_INVITE}`,
          method: 'PATCH',
          body: {},
        };
      },
    }),
    getUsersList: build.query<
      TPageableDataWithContentUI<TUserListDataUI[]>,
      TTableFilters
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: `${baseUrl}/user-role`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && { ...userListFilterConverter(filters) }),
          },
        };
      },
      transformResponse: (
        data: TPageableDataWithContentDB<TUserListDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            userListGetConverter.fromDb(workshop),
          ),
        };
      },
      providesTags: ['user-identity', 'user-identity-no-profile', 'users-list'],
    }),
    exportUsersListCSV: build.query<TExportCsvUI, void>({
      query() {
        return {
          url: `${baseUrl}/user-export`,
          method: 'GET',
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('zip').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    exportAdminListCSV: build.query<TExportCsvUI, void>({
      query() {
        return {
          url: `${baseUrl}/admin-export`,
          method: 'GET',
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('zip').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    getAdminUsersList: build.query<
      TPageableDataWithContentUI<TAdminUserListDataUI[]>,
      TTableFilters
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: `${baseUrl}/admin-role`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && { ...userListFilterConverter(filters) }),
          },
        };
      },
      transformResponse: (
        data: TPageableDataWithContentDB<TAdminUserListDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            adminUserListGetConverter.fromDb(workshop),
          ),
        };
      },
      providesTags: ['user-identity', 'user-identity-no-profile', 'users-list'],
    }),
    getUserIdentity: build.query<
      TUserIdentityUI[],
      { profileTypes?: EUserType }
    >({
      query({ profileTypes }) {
        return {
          url: `${baseUrl}/${EUserUrls.IDENTITY}`,
          method: 'GET',
          params: {
            ...(profileTypes && { profileTypes }),
          },
        };
      },
      transformResponse: (data: TUserIdentityDB[]) => {
        return data?.length
          ? data.map(item => userIdentityGetConverter.fromDb(item))
          : [];
      },
      providesTags: ['user-identity'],
    }),
    getUserIdentityNoProfile: build.query<
      TUserIdentityUI[],
      { profileType?: EUserType }
    >({
      query({ profileType }) {
        return {
          url: `${baseUrl}/${EUserUrls.IDENTITY}/${EUserUrls.NO_PROFILE}`,
          method: 'GET',
          params: {
            ...(profileType && { profileType }),
          },
        };
      },
      transformResponse: (data: TUserIdentityDB[]) => {
        return data?.length
          ? data.map(item => userIdentityGetConverter.fromDb(item))
          : [];
      },
      providesTags: ['user-identity-no-profile'],
    }),
    // addNewUser: build.mutation<string, TAddNewUserUI>({
    //   query(body) {
    //     return {
    //       url: `${baseUrl}/${EUserUrls.INVITE}`,
    //       method: 'POST',
    //       body: addNewUserConverter.toDb(body),
    //     };
    //   },
    //   invalidatesTags: ['users'],
    // }),
    addNewUser: build.mutation<string, TAddNewUserUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EUserUrls.TEAM}`,
          method: 'PATCH',
          body: addNewUserConverter.toDb(body),
        };
      },
      invalidatesTags: [
        'user-identity',
        'user-identity-no-profile',
        'users-list',
      ],
    }),
    // deleteUser: build.mutation<void, TDeleteUserUI>({
    //   query(body) {
    //     return {
    //       url: `${baseUrl}`,
    //       method: 'DELETE',
    //       body: deleteUserConverter.toDb(body),
    //     };
    //   },
    //   invalidatesTags: ['users'],
    //   onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     queryFulfilled
    //       .then(() => {
    //         dispatch(
    //           peopleApi.util.invalidateTags(['peoples', 'site-coordinators']),
    //         );
    //         dispatch(elcsApi.util.invalidateTags(['contacts-identity']));
    //         dispatch(elcsApi.util.invalidateTags(['elac-contacts-identity']));
    //       })
    //       .catch(() => {
    //         // do nothing
    //       });
    //   },
    // }),
    deleteUser: build.mutation<void, TDeleteUserUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EUserUrls.TEAM}`,
          method: 'DELETE',
          body: deleteUserConverter.toDb(body),
        };
      },
      invalidatesTags: [
        'users',
        'user-identity',
        'user-identity-no-profile',
        'users-list',
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              peopleApi.util.invalidateTags(['peoples', 'site-coordinators']),
            );
            dispatch(elcsApi.util.invalidateTags(['contacts-identity']));
            dispatch(elcsApi.util.invalidateTags(['elac-contacts-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateUser: build.mutation<string, TUpdateUserUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'PUT',
          body: updateUserConverter.toDb(body),
        };
      },

      invalidatesTags: ['users'],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useDeleteUserMutation,
  useUserAcceptInviteMutation,
  useUpdateUserMutation,
  useGetUsersListQuery,
  useLazyGetUsersListQuery,
  useLazyExportUsersListCSVQuery,
  useLazyExportAdminListCSVQuery,
  useGetAdminUsersListQuery,
  useLazyGetAdminUsersListQuery,
  useAddNewUserMutation,
  useGetUserIdentityQuery,
  useGetUserIdentityNoProfileQuery,
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
  useResendInviteMutation,
} = userGrowApi;
