import React from 'react';
import { Row, Col, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TAdditionalAdults } from 'data/types/registrants.types';
import { TFunction } from 'i18next';
import Button from 'components/Base/Button/Button';
import { FormItem, FormList } from 'components/Base/Form';
import { Input } from 'components/Base/Input';
import { DeleteTrashOutline } from 'assets/icon/icon';
import {
  formatPhoneWhileTyping,
  createPhoneValidator,
} from 'helpers/phoneUtils';

interface AdditionalAdultInformationProps {
  t: TFunction;
  validationRules: {
    required: () => any;
    email: () => any;
    phoneNumber: () => any;
  };
}

const AdditionalAdultInformation: React.FC<AdditionalAdultInformationProps> = ({
  t,
  validationRules,
}) => {
  const form = Form.useFormInstance();

  const handlePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const input = e.target.value;
    const formattedPhone = formatPhoneWhileTyping(input);

    const additionalAdults = form.getFieldValue('additionalAdults') || [];

    if (additionalAdults[index]) {
      additionalAdults[index].phone = formattedPhone;

      form.setFieldValue('additionalAdults', [...additionalAdults]);
    }
  };

  const validatePhoneNumber = createPhoneValidator(
    t('MOBILE_FORMAT', 'Please enter a valid 10-digit phone number'),
    t('MOBILE_REQUIRED', 'Mobile number is required'),
  );

  return (
    <FormList name="additionalAdults" data-cy="additional-adult">
      {(fields, { add, remove }) => (
        <>
          {fields.length > 0 && (
            <h3>
              {t('ADDITIONAL_ADULT_INFO', 'Additional Adult Information')}
            </h3>
          )}
          {fields.map(({ key, name, ...restField }, index) => (
            <div key={key} style={{ position: 'relative' }}>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem
                    {...restField}
                    name={[name, 'firstName']}
                    label={t('FIRST_NAME', 'First Name')}
                    rules={[validationRules.required()]}
                    required
                    data-cy="additional-adult-fname"
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    {...restField}
                    name={[name, 'lastName']}
                    label={t('LAST_NAME', 'Last Name')}
                    rules={[validationRules.required()]}
                    required
                    data-cy="additional-adult-lname"
                  >
                    <Input />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem
                    {...restField}
                    name={[name, 'phone']}
                    label={t('MOBILE', 'Mobile')}
                    rules={[{ validator: validatePhoneNumber }]}
                    required
                    data-cy="additional-adult-mobile"
                  >
                    <Input
                      onChange={e => handlePhoneChange(e, index)}
                      placeholder="(555) 555-5555"
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    {...restField}
                    name={[name, 'email']}
                    label={t('EMAIL', 'Email')}
                    rules={[
                      validationRules.required(),
                      validationRules.email(),
                    ]}
                    required
                    data-cy="additional-adult-email"
                  >
                    <Input />
                  </FormItem>
                </Col>
              </Row>
              <Button
                variant="ghost"
                onClick={() => remove(name)}
                style={{
                  position: 'absolute',
                  top: -50,
                  right: 0,
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                data-cy="delete-additional-adult"
              >
                <DeleteTrashOutline size={16} />
              </Button>
            </div>
          ))}
          {fields.length === 0 && (
            <FormItem>
              <Button
                variant="link"
                onClick={() =>
                  add({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                  } as TAdditionalAdults)
                }
                icon={<PlusOutlined />}
                disabled={fields.length > 0}
                data-cy="add-additional-adult"
              >
                {t('ADD_ADDITIONAL_ADULT', 'Add Additional Adult')}
              </Button>
            </FormItem>
          )}
        </>
      )}
    </FormList>
  );
};

export default AdditionalAdultInformation;
