import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import { TFunction } from 'i18next';
import { FormItem } from 'components/Base/Form';
import { Input } from 'components/Base/Input';
import {
  formatPhoneWhileTyping,
  createPhoneValidator,
} from 'helpers/phoneUtils';

export type TRegistrantInfoSectionProps = {
  t: TFunction;
  validationRules: any;
};

const RegistrantInformation: React.FC<TRegistrantInfoSectionProps> = ({
  t,
  validationRules,
}) => {
  const [phoneValue, setPhoneValue] = useState<string>('');
  const form = Form.useFormInstance();

  useEffect(() => {
    const initialPhone = form.getFieldValue('phone');
    if (initialPhone) {
      const formattedPhone = formatPhoneWhileTyping(initialPhone);
      setPhoneValue(formattedPhone);
      form.setFieldValue('phone', formattedPhone);
    }
  }, [form]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const formattedPhone = formatPhoneWhileTyping(input);

    setPhoneValue(formattedPhone);
    form.setFieldValue('phone', formattedPhone);
  };

  const validatePhoneNumber = createPhoneValidator(
    t('MOBILE_FORMAT', 'Please enter a valid 10-digit phone number'),
    t('MOBILE_REQUIRED', 'Mobile number is required'),
  );

  return (
    <>
      <h3>{t('REGISTRANT_INFO', 'Registrant Information')}</h3>

      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            name="firstName"
            label={t('FIRST_NAME', 'First Name')}
            rules={[validationRules.required()]}
            required
          >
            <Input />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            name="lastName"
            label={t('LAST_NAME', 'Last Name')}
            rules={[validationRules.required()]}
            required
          >
            <Input />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            name="phone"
            label={t('MOBILE', 'Mobile')}
            rules={[{ validator: validatePhoneNumber }]}
            required
          >
            <Input
              value={phoneValue}
              onChange={handlePhoneChange}
              placeholder="(555) 555-5555"
            />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            name="email"
            label={t('EMAIL', 'Email')}
            rules={[validationRules.required(), validationRules.email()]}
            required
          >
            <Input />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default RegistrantInformation;
