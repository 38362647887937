import { useMemo } from 'react';
import { Row, Col, Text, filterTitleOption } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Select } from 'components/Base/Select';
import { useGetUserIdentityNoProfileQuery } from 'redux/apiSlice/userGrow.slice';
import { Form, FormItem } from 'components/Base/Form';
import { TAddNewAdminFormProps } from './AddNewAdminForm.types';
import useGetFormData from './AddNewAdminForm.data';

const AddNewAdminForm = ({ form, profileType }: TAddNewAdminFormProps) => {
  const theme = useTheme();
  const { addNewAdminFormFields, initialValues } = useGetFormData();
  const { userId } = addNewAdminFormFields;
  const { data, isLoading } = useGetUserIdentityNoProfileQuery({
    profileType,
  });

  const userOptions = useMemo(() => {
    return data?.map(item => ({
      label: (
        <Row>
          <Col xs={24}>{item.fullName}</Col>
          <Col xs={24}>
            <Text
              fontSize={theme.fontSizeMd}
              color={theme.buttonColorPrimaryDisabledText}
            >
              {item.email}
            </Text>
          </Col>
        </Row>
      ),
      value: item.id,
      title: item.fullName,
    }));
  }, [data]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      style={{ margin: 0, padding: '16px 0' }}
    >
      <FormItem {...userId}>
        <Select
          options={userOptions}
          loading={isLoading}
          placeholder={userId.placeholder}
          filterOption={filterTitleOption}
          showSearch
          optionLabelProp="title"
        />
      </FormItem>
    </Form>
  );
};

export default AddNewAdminForm;
