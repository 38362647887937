/**
 * Strips all non-numeric characters from a phone number
 * @param phoneNumber - Phone number in any format (e.g. "(612) 504-7154")
 * @returns Phone number with only digits (e.g. "6125047154") or empty string if input is null/empty
 */
export const stripPhoneNumber = (phoneNumber: string | null): string => {
  if (!phoneNumber) {
    return '';
  }

  // Remove all non-digit characters (spaces, parentheses, dashes, etc.)
  return phoneNumber.replace(/\D/g, '');
};

/**
 * Converts a phone number to E.164 format (+1XXXXXXXXXX)
 * @param phoneNumber - Phone number in any format (e.g. "(612) 504-7154")
 * @returns Phone number in E.164 format (e.g. "+16125047154") or empty string if input is null/empty
 */
export const formatPhoneToE164 = (
  phoneNumber: string | null | undefined,
): string | null => {
  if (!phoneNumber) {
    return null;
  }

  // Remove any non-numeric characters from the input
  const numericValue = phoneNumber.replace(/\D/g, '');

  // Add the +1 prefix
  return `+1${numericValue}`;
};

/**
 * Formats a phone number to (nnn) nnn-nnnn format
 * @param phoneNumber - Phone number in any format (e.g. "6125047154" or "+16125047154")
 * @returns Phone number in (nnn) nnn-nnnn format or empty string if input is null/empty
 */
export const formatPhoneDisplay = (value: string | null): string => {
  if (!value) {
    return '';
  }

  // if phone number is not us number do not format it
  if (!value.startsWith('+1') || value.length !== 12) {
    return value;
  }

  // Remove the +1 prefix and format as (xxx) xxx-xxxx
  const numericValue = value.substring(2); // More efficient than replace
  return `(${numericValue.substring(0, 3)}) ${numericValue.substring(
    3,
    6,
  )}-${numericValue.substring(6, 10)}`;
};

/**
 * Formats a phone number as (XXX) XXX-XXXX while typing
 * Limits to 10 digits and formats immediately
 *
 * @param value - Phone number in any format
 * @returns Formatted phone number as (XXX) XXX-XXXX
 */
export const formatPhoneWhileTyping = (value: string | null): string => {
  if (!value) {
    return '';
  }

  // Extract only the digits
  const digits = stripPhoneNumber(value);

  // Make sure we have at most 10 digits
  const truncatedDigits = digits.slice(0, 10);

  // Format as (XXX) XXX-XXXX
  let formattedPhone = '';
  if (truncatedDigits.length > 0) {
    formattedPhone += '(';
    formattedPhone += truncatedDigits.substring(0, 3);
    if (truncatedDigits.length > 3) {
      formattedPhone += ') ';
      formattedPhone += truncatedDigits.substring(3, 6);
      if (truncatedDigits.length > 6) {
        formattedPhone += '-';
        formattedPhone += truncatedDigits.substring(6, 10);
      }
    }
  }

  return formattedPhone;
};

/**
 * Validates if a phone number has exactly 10 digits
 *
 * @param phoneNumber - Phone number in any format
 * @returns true if the phone number has exactly 10 digits, false otherwise
 */
export const isValidPhoneNumber = (phoneNumber: string | null): boolean => {
  if (!phoneNumber) {
    return false;
  }

  const digits = stripPhoneNumber(phoneNumber);
  return digits.length === 10;
};

/**
 * Creates a validation function for form validation
 *
 * @param errorMessage - The error message to display if the phone number is invalid
 * @param requiredMessage - The error message to display if the phone number is required but not provided
 * @returns A validation function that can be used in form rules
 */
export const createPhoneValidator = (
  errorMessage: string,
  requiredMessage: string,
) => {
  return (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error(requiredMessage));
    }

    // Check if we have exactly 10 digits
    if (!isValidPhoneNumber(value)) {
      return Promise.reject(new Error(errorMessage));
    }

    return Promise.resolve();
  };
};
