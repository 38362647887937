import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Button = styled(AntButton)<{
  $position: 'first' | 'middle' | 'last';
  $active: boolean;
  $disabled: boolean;
  size?: 'small' | 'middle' | 'large';
}>`
  background: transparent;
  border: 1px solid #dedede;
  font-size: 16px;
  cursor: pointer;
  flex: 1;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    color: #0077a0 !important;
    border-color: #0077a0 !important;
  }

  &:disabled {
    color: #808080;
    border-color: #dedede;
    background-color: #ffffff;
    cursor: not-allowed;
  }

  ${({ $position }) => {
    if ($position === 'first') {
      return `
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `;
    }
    if ($position === 'last') {
      return `
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `;
    }
    return `
      border-radius: 0;
      border-left: none;
      border-right: none;
    `;
  }}

  ${({ $active }) =>
    $active &&
    `
     background-color: #0077a0;
     border-color: #0077a0;
     color: #ffffff;
     
     &:hover {
       background-color: #0197B8 !important;
       border-color: #0197B8 !important;
       color: #ffffff !important;
     }
     
     &:disabled {
      background-color: #0077a0;
      border-color: #0077a0;
      color: #ffffff;
    }
  `}

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none;
  `}
`;
