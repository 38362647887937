import dayjs from 'dayjs';
import React from 'react';
import { TRtkErrorType, useShowToastNotification } from '@packages/utils';
import { ENotificationType, TMenuProps } from '@idaho-aeyc/ui-kit';
import { getFormattedDateMDY } from '../../../../../utils/dateUtils';
import { downloadCSV } from '../../../../../helpers/downloadCsv';
import ActionGroupItemLabel from '../../../../../components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import ActionsButtonDetailsPage from '../../../../../components/Shared/ActionsButtonDetailsPage';
import {
  useLazyGetAdminUsersListQuery,
  useLazyExportAdminListCSVQuery,
  useLazyExportUsersListCSVQuery,
  useLazyGetUsersListQuery,
} from '../../../../../redux/apiSlice/userGrow.slice';
import { TAdminsDataUI } from '../../../../../data/types/admin.types';
import { TPageableDataWithContentUI } from '../../../../../data/types/generic.types';
import { TUserListDataUI } from '../../../../../data/types/user.types';

export type TActionProps = {
  isAdmin?: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowsKeys: React.Key[]; // replace with actual type when data is available
};

const profileTypeDisplayNames: { [key: string]: string } = {
  ELC_PM: 'ELC Project Manager',
  SITE_COORDINATOR: 'Site Coordinator',
  ELC_LEAD: 'Lead',
  READY_ADMIN: 'RFK Admin',
};

const adminProfileTypes = ['ELC_LEAD', 'READY_ADMIN'];
const userProfileTypes = ['ELC_PM', 'SITE_COORDINATOR'];

const ExportActions: React.FC<TActionProps> = ({
  isAdmin = false,
  selectedRowsKeys,
  setShowAlert,
}) => {
  const { showToastNotification } = useShowToastNotification();

  const [getAdminUsersList] = useLazyGetAdminUsersListQuery();
  const [getUserList] = useLazyGetUsersListQuery();
  const [exportUsersList] = useLazyExportUsersListCSVQuery();
  const [exportAdminList] = useLazyExportAdminListCSVQuery();

  const handleExportAll = async () => {
    try {
      if (isAdmin) {
        await exportAdminList().unwrap();
      } else {
        await exportUsersList().unwrap();
      }
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  if (!selectedRowsKeys) return null;

  const exportSelectedRows = async () => {
    if (!selectedRowsKeys.length) {
      setShowAlert(true);
      return;
    }

    let data: TPageableDataWithContentUI<TAdminsDataUI[] | TUserListDataUI[]>;

    const params = {
      page: 0,
      pageSize: 10000,
      sortColumn: {
        sortField: undefined,
        sortDirection: undefined,
      },
    };

    if (isAdmin) {
      data = await getAdminUsersList(params).unwrap();
    } else {
      data = await getUserList(params).unwrap();
    }

    const selectedRows: TAdminsDataUI[] =
      data?.content.filter((content: TAdminsDataUI) =>
        selectedRowsKeys.includes(content.adminId),
      ) || [];

    if (!selectedRows?.length) {
      setShowAlert(true);
      return;
    }

    // Get all unique userProfileTypes
    const allProfileTypes = new Set<string>();
    selectedRows.forEach(row => {
      row.userProfileStatus?.forEach(status => {
        allProfileTypes.add(status.userProfileType);
      });
    });

    const orderedProfileTypes = isAdmin ? adminProfileTypes : userProfileTypes;

    // Create headers
    const headers = [
      'First Name',
      'Last Name',
      'Email',
      'Mobile',
      ...orderedProfileTypes.map(
        profileType => profileTypeDisplayNames[profileType] || profileType,
      ),
      'Last Logged In',
    ];

    const selectedRowsTransformed = selectedRows.map(row => {
      if (row === undefined) return undefined;

      const {
        firstName,
        lastName,
        email,
        phone,
        userProfileStatus,
        lastLoggedInDate,
      } = row;

      // Create an object to store status for each profile type
      const profileStatusMap: { [key: string]: string } = {};
      userProfileStatus?.forEach(status => {
        profileStatusMap[status.userProfileType] = status.status;
      });

      return {
        firstName,
        lastName,
        email,
        phone: phone || '',
        ...profileStatusMap,
        lastLoggedInDate,
      };
    });

    const formattedDate = getFormattedDateMDY();

    downloadCSV(
      headers,
      selectedRowsTransformed,
      record => [
        `"${record.firstName}"`,
        `"${record.lastName}"`,
        `"${record.email}"`,
        `"${record.phone}"`,
        ...orderedProfileTypes.map(
          profileType => `"${record[profileType] || ''}"`,
        ),
        `"${
          record.lastLoggedInDate &&
          record.lastLoggedInDate !== '-' &&
          record.lastLoggedInDate !== ''
            ? dayjs(record.lastLoggedInDate).format('MMM DD, YYYY h:mmA')
            : ''
        }"`,
      ],

      isAdmin ? `Admins_${formattedDate}.csv` : `Users_${formattedDate}.csv`,
    );
  };

  const items: TMenuProps['items'] = [
    {
      label: (
        <ActionGroupItemLabel
          onClick={exportSelectedRows}
          label="Export Selected"
        />
      ),
      key: 'EXPORT',
    },
    {
      label: (
        <ActionGroupItemLabel onClick={handleExportAll} label="Export All" />
      ),
      key: 'EXPORT_ALL',
    },
  ];

  return <ActionsButtonDetailsPage items={items} />;
};

export default ExportActions;
