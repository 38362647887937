import { FC, useEffect, useState } from 'react';
import { find } from 'lodash';
import { Table } from 'components/Base/Table';
import { dateShortenFormat } from '@idaho-aeyc/ui-kit';
import {
  TAttendanceListUI,
  TRegistrantUI,
  TAttendancesGetUI,
  ERegistrantsColumnKey,
  EAttendanceStatus,
} from 'data/types/registrants.types';
import { workshopsApi } from 'redux/apiSlice/workshops.slice';
import { useParams } from 'react-router-dom';
import { useGetRegistrantsQuery } from 'redux/apiSlice/registrants.slice';
import { collectAgeGroupColumnItemsInList } from 'components/Shared/AgeGroupesColumn/helpers';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import dayjs from 'dayjs';
import { TAttendanceListProps } from './AttendanceList.types';
import useGetData from './AttendanceList.data';
import useAgeGroupLanguageFilter from '../../RegistrantsTab/RegistrantsList/useAgeGroupLanguageFilter';

const AttendanceList: FC<TAttendanceListProps> = ({
  workshopId,
  workshopStatus,
  workshopType,
  csvFileNamePrefix,
  title,
  partType = '',
  sessionDateTime,
  sessions,
}) => {
  const [attendanceData, setAttendanceData] = useState<TAttendanceListUI[]>([]);
  const { defaultColumns, defaultSortParam } = useGetData({
    workshopId,
    workshopStatus,
    workshopType,
  });

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TAttendanceListUI>({
    storagePath: `${ETableStoragePaths.ATTENDANCE_LIST}-${workshopId}`,
    defaultColumns,
    defaultSortColumn: defaultSortParam,
  });

  const params = useParams();
  const { id } = params as { id: string };

  const { data: workshopDetailsCachedData } =
    workshopsApi.endpoints.getWorkshopById.useQueryState(
      {
        id,
      },
      { skip: !id },
    );

  const workshopClasses = workshopDetailsCachedData?.classes.value || [];

  const ageGroupLanguageFilters = useAgeGroupLanguageFilter(workshopClasses);
  useEffect(() => {
    if (ageGroupLanguageFilters) {
      setColumns(
        columns.map(item => {
          if (
            item.key ===
              ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP &&
            ageGroupLanguageFilters
          ) {
            item.filters = ageGroupLanguageFilters;
          }
          return item;
        }),
      );
    }
  }, [workshopClasses]);

  const { data: registrantsData, isFetching } = useGetRegistrantsQuery(
    {
      page: 0,
      pageSize: 2000,
      sortColumn,
      filters: selectedFilters,
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  const processAttendanceData = (content: TRegistrantUI[]) => {
    if (!content?.length) return [];

    return content
      .filter(item => item.status !== 'UNREGISTERED')
      .map((item: TRegistrantUI) => {
        const {
          firstName,
          lastName,
          childrenNames,
          spanishAgeGroups,
          englishAgeGroups,
          attendances,
          allowedTakePicture,
          countOfChildren,
          orientationKit,
          registrantId,
          status,
        } = item;

        const currentAttendance = find(attendances, {
          partType,
        }) as TAttendancesGetUI;
        const attendance = currentAttendance?.attendanceStatus;
        const currentAttendanceSessionId =
          currentAttendance?.sessionAttendanceId;

        return {
          firstName,
          lastName,
          photo: allowedTakePicture,
          childrenNames,
          spanishAgeGroups,
          englishAgeGroups,
          attendance,
          countOfChildren,
          kits: currentAttendance?.kitCount || 0,
          binders: {
            received: orientationKit ? orientationKit.received : false,
            requested: orientationKit ? orientationKit.requested : false,
          },
          attendances,
          registrantId,
          currentAttendanceSessionId,
          sessionDateTime,
          sessions,
          status,
        };
      });
  };

  useEffect(() => {
    if (registrantsData) {
      const data = processAttendanceData(registrantsData.content);
      setAttendanceData(data);
    }
  }, [registrantsData]);

  const csvFileName = `${csvFileNamePrefix}_${title}_${dayjs().format(
    dateShortenFormat,
  )}.csv`;

  const checkBinders = (binders: { received: boolean; requested: boolean }) => {
    if (!binders.requested) return 'Not Requested';
    if (binders.received) return 'Received';
    return 'Not Received';
  };

  return (
    <Table<TAttendanceListUI>
      exportCSV={{
        filename: csvFileName,
        exportData: (attendanceData || []).map(el => {
          const ageGroup = collectAgeGroupColumnItemsInList(
            el.englishAgeGroups.concat(el.spanishAgeGroups),
          );
          return {
            ...el,
            photo: el.photo ? 'Allowed' : 'Not Allowed',
            attendance:
              el.attendance === EAttendanceStatus.ATTENDED
                ? 'Attended'
                : 'Absent',
            kits: `${el.countOfChildren}, ${el.kits}`,
            binders: checkBinders(el.binders),
            ageGroup,
          };
        }),
      }}
      tableTitle={title}
      tabHeight={44}
      onFiltersReset={onFiltersReset}
      hasFilters={hasFilters}
      currentPage={page}
      columns={columns}
      dataSource={attendanceData}
      loading={isFetching}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalDataCount={registrantsData?.totalElements}
      onChange={handleTableChange}
      rowKey={record => record.registrantId}
      storagePath={ETableStoragePaths.ATTENDANCE_LIST}
    />
  );
};

export default AttendanceList;
