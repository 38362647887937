import { useState } from 'react';
import { Text, useTranslation } from '@packages/utils';
import { TMenuProps } from '@idaho-aeyc/ui-kit';
import { EUserType } from 'data/types/user.types';
import { useTheme } from 'styled-components';
import { ButtonDropdown } from 'components/Base/ButtonDropdown';
import { Icon, EIconNames } from 'components/Base/Icon';
import AddNewAdminModal from './AddNewAdminModal';

const AddUserAction = () => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.team.list.actions.add-user.add-user-action',
  });
  const [open, setOpen] = useState<boolean>(false);
  const [profileType, setProfileType] = useState<EUserType>(
    EUserType.READY_ADMIN,
  );

  // @ts-ignore
  const handleMenuClick: TMenuProps['onClick'] = e => {
    setProfileType(e.key as EUserType);
    setOpen(true);
  };

  const items: TMenuProps['items'] = [
    {
      label: t('options.READY_ADMIN'),
      key: EUserType.READY_ADMIN,
      onClick: handleMenuClick,
    },
    {
      label: t('options.ELC_LEAD'),
      key: EUserType.ELC_LEAD,
      onClick: handleMenuClick,
    },
  ];

  return (
    <>
      <ButtonDropdown
        buttonProps={{
          children: (
            <>
              <Icon
                icon={EIconNames.PLUS}
                color={theme.colorWhitePrimary}
                className="pointer"
              />
              <Text color={theme.colorWhitePrimary}>{t('BUTTON')}</Text>
            </>
          ),
          color: 'primary',
          style: {
            color: `${theme.colorWhitePrimary} !important`,
            backgroundColor: '#00779F',
            borderColor: '#00779F',
          },
        }}
        dropdownProps={{
          menu: {
            items,
          },
          rootClassName: 'button-dropdown',
          placement: 'bottomRight',
        }}
      />
      <AddNewAdminModal
        setOpen={setOpen}
        open={open}
        profileType={profileType}
        modalTitle={t(`options.${profileType}`)}
      />
    </>
  );
};

export default AddUserAction;
