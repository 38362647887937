import styled from 'styled-components';
import AlertIcon from 'assets/images/alert.svg';
import { Image } from 'antd';

const WarningWrapper = styled.div`
  border: 1px solid #ff8b4d;
  border-radius: 4px;
  background-color: #fff7f0;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  .ant-image,
  .ant-image-mask,
  .ant-image-img {
    pointer-events: none !important;
  }
`;

const InfoWrapper = styled.div`
  border: 1px solid #1890ff;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  .ant-image,
  .ant-image-mask,
  .ant-image-img {
    pointer-events: none !important;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #656565;
`;

export const CapacityWarning = ({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) => {
  return (
    <WarningWrapper>
      <Image alt="Registration Modal Art" src={AlertIcon} width={60} />
      <div>{message}</div>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </WarningWrapper>
  );
};

export const InfoWarning = ({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) => {
  return (
    <InfoWrapper>
      <Image alt="Registration Modal Art" src={AlertIcon} width={60} />
      <div>{message}</div>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </InfoWrapper>
  );
};

export const AgeGroupWarning = ({
  ageGroup,
  onClose,
}: {
  ageGroup: string;
  onClose: () => void;
}) => {
  return (
    <WarningWrapper>
      <Image alt="Registration Modal Art" src={AlertIcon} width={60} />
      <div>
        Class of an age group ({ageGroup}) is full. You can still proceed and we
        will add you to the waitlist.
      </div>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </WarningWrapper>
  );
};
